import Peer from "skyway-js";
import { v4 } from "uuid";
import Db, { PEERID } from "../../helper/Db";

export default class Skyway {
  constructor() {
    // const params = new URLSearchParams(window.location.search);
    // this.meshId = params.get("id");
    this.meshId = null;
    this.svId = "";
    console.log(this.meshId);
    this.handlerMap = new Map();
    // This login Data should be align via Login.js
    this.loginData = { svId: null, nickName: null, isConnected: false };
    this.peerId = null;
  }

  addReceiveHandler(handler, pageId) {
    console.log("adding handler", pageId);
    if (this.handlerMap.has(pageId)) {
      this.handlerMap.delete(pageId);
    }
    this.handlerMap.set(pageId, handler);
  }

  removeReceiveHandler(pageId) {
    console.log("removing handler", pageId);
    this.handlerMap.delete(pageId);
  }

  open() {
    return new Promise(async (resolve) => {
      // Fixing the client's peerId in case of crash, ID will still be same
      this.peerId = await Db(PEERID);
      if (!this.peerId) {
        this.peerId = v4();
      }

      this.peer = new Peer(this.peerId, { key: process.env.REACT_APP_SKYWAY_API_KEY, debug: 1 });
      this.peer.on("open", () => {
        resolve(true);
      });
    });
  }

  joinRoom() {
    return new Promise((resolve) => {
      this.room = this.peer.joinRoom(this.meshId, { mode: "mesh" });
      this.room.on("open", () => {
        console.log("[Joined urbano room]...");
        resolve(true);
      });
      this.room.on("data", (data) => {
        console.log(data);
        this.handlerMap.forEach((handler, key) => {
          // call to each listener with listener object
          console.log(handler)
          handler.receive(data, handler)
        });

        // Check if loginResult from reconnection
        if (
          data.data.messageType === "loginResult" &&
          !data.data.isRejected &&
          this.loginData.nickName === data.data.nickName
        ) {
          this.loginData.isConnected = true;
          this.loginData.svId = data.data.svId
        }
      });
      this.room.on("peerLeave", (peerId) => {
        // In case ZV crashes, start interval so client keep trying to reconnect
        if (peerId === this.loginData.svId) {
          this.loginData.isConnected = false;

          const interval = setInterval(async () => {
            if (!this.loginData.isConnected) {
              let sendMessage = {}
              for (let [key, handler] of this.handlerMap) {
                console.log(key, handler);
                // call to each listener's collect data function
                if (handler.collectReconnectionData) {
                  const collectData = await handler.collectReconnectionData(handler);
                  sendMessage = {
                    ...sendMessage,
                    ...collectData
                  }
                }
              }
              console.log("[login again]...");
              this.sendData({
                ...sendMessage,
                messageType: "login",
                nickName: this.loginData.nickName,
                id: this.loginData.nickName,
                reconnect: true,
              });
            } else {
              clearInterval(interval);
            }
          }, 3000);
        }
      });

      this.room.on("error", (error) => {
        console.log("receive error:" + error);
        this.handlerMap.forEach((handler, key) => {
          handler.error(error);
        });
      });
    });
  }

  sendData(data) {
    if (this.room) {
      this.room.send(data);
    }
  }
}

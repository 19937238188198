import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./ChoosePage.css";
import { Redirect } from "react-router-dom";
import AppHeader from "../component/AppHeader.js";
import EditorMain from "../component/editor/EditorMain.js";
import AppFooter from "../component/AppFooter.js";
import ZvCursor from "../component/editor/ZvCursor.js";
import Db, { CHOOSE_DELAY, CURRENT_MS, IS_WAITING_SORT, MOVE_PAGE } from "../helper/Db";

class ChoosePage extends Component {
  constructor(props) {
    super(props);
    let chara = this.props.chara;
    let scene = this.props.scene;
    let nickName = this.props.nickName;
    const params = new URLSearchParams(window.location.search);
    // if (!chara) {
    //   chara = params.get("chara");
    //   chara = chara || "V";
    //   let charaFullName = chara === "V" ? "vita" : chara === "U" ? "urbano" : "bache";
    //   this.props.setChooseCharacter(charaFullName);
    // }
    if (!scene) {
      scene = params.get("scene");
      scene = scene || "1";
      this.props.setChooseScene(scene);
    }
    // if (!nickName) {
    //   nickName = "test";
    //   this.props.setNickName(nickName);
    // }

    this.resizeEventListener = () => {
      this.setState({
        topMargin: window.innerHeight >= 900 ? (window.innerHeight - 900) / 2 : 0,
        leftMargin: window.innerWidth >= 1440 ? (window.innerWidth - 1440) / 2 : 0
      })
    }

    this.state = {
      currentTimeMillisec: 0,
      chara,
      scene,
      nickName,
      isBeingHelped: false,
      zvCursorX: 0,
      zvCursorY: 0,
      topMargin: window.innerHeight >= 900 ? (window.innerHeight - 900) / 2 : 0,
      leftMargin: window.innerWidth >= 1440 ? (window.innerWidth - 1440) / 2 : 0,
      gotoPreview: false,
    }
    this._footer = React.createRef();
    this._editor = React.createRef();
    this._page = React.createRef();

    this.mouseHandler = undefined;
  }

  componentDidMount() {
    if (!this.props.skyway) {
      this.props.history.push("/login");
      return;
    }

    window.addEventListener("mousemove", (e) => {
      this.mouseCursor(e);
    });
    window.addEventListener('resize', this.resizeEventListener)
    this.mouseHandler = window.setInterval(() => {
      if (this.props.skyway && this._page.current) {
        const rect = this._page.current.getBoundingClientRect();
        const x = (this.pointX - rect.x) / 1440;
        const y = (this.pointY - rect.y) / 900;
        const pointX = x > 1 ? 1 : x;
        const pointY = y > 1 ? 1 : y;

        const chooseDelay = this._footer.current.getChooseDelay();

        this.props.skyway.sendData({
          messageType: "mousePosition",
          nickName: this.props.nickName,
          pointX,
          pointY,
          step: 1,
          currentTime: this.state.currentTimeMillisec,
          chooseDelay,
        });

        Db(CHOOSE_DELAY, chooseDelay);
        Db(CURRENT_MS, this.state.currentTimeMillisec);
      }
    }, 200);

    this.props.skyway.addReceiveHandler(
      {
        receive: (e) => {
          let type = e.data.messageType;
          console.log(e);
          switch (type) {
            case "respondHelp":
              break;
            case "ZVHelpStart":
              if (e.data.nickName === this.props.nickName) {
                this.setState({ isBeingHelped: true });
              }
              break;
            case "ZVHelpEnd":
              console.log("ZVHelpEnd", e.data);
              if (e.data.nickName === this.props.nickName) {
                if (this._editor.current) {
                  this._editor.current.helpEnd();
                }
                this.setState({ isBeingHelped: false });
              }
              break;
            case "ZVMousePosition":
              console.log("ZVMousePosition", e.data);
              if (e.data.nickName !== this.props.nickName) break;
              this.setState({
                zvCursorX: 1440 * e.data.pointX + this.state.leftMargin,
                zvCursorY: 900 * e.data.pointY + this.state.topMargin
              });
              break;
              case "movePage":
                let page = e.data.target;
                if (page === "sort") {
                  Db(IS_WAITING_SORT, true);
                  Db(MOVE_PAGE, "/preview");
                  this.setState({
                    gotoPreview: true,
                  });
                } else if (page === "chooseBGM") {
                  this.props.history.push("/chooseBGM");
                  Db(MOVE_PAGE, "/chooseBGM/");
                } else if (page === "screening") {
                  Db(MOVE_PAGE, "/screening/");
                  this.props.history.push("/screening/");
                }
                break;
            default:
              break;
          }
        },
        erro: (e) => {},
      },
      "edit"
    );

    // Send Reset animation
    this.props.setAnimationJSON( {
      character: this.state.chara,
      scene: this.state.scene,
      start: 0,
      nickname: this.state.nickName,
      effects: []
    });

    if (this._editor.current) {
      Db(CHOOSE_DELAY).then((res) => {
        if (res) {
          this._footer.current.setChooseDelay(res);
        }
      });
    }
  }

  componentWillMount() {
    if (this.props.skyway) this.props.skyway.removeReceiveHandler("edit");
    if (this.mouseHandler) window.clearInterval(this.mouseHandler);
  }

  componentWillUnmount() {
    if (this.props.skyway) this.props.skyway.removeReceiveHandler("edit");
    if (this.mouseHandler) window.clearInterval(this.mouseHandler);
    window.removeEventListener('resize', this.resizeEventListener)
  }

  mouseCursor(e) {
    this.pointX = e.clientX;
    this.pointY = e.clientY;
  }

  changeTime(time_ms) {
    this.setState({
      currentTimeMillisec: time_ms,
    });
  }

  helpReq() {
    if (this.props.skyway)
      this.props.skyway.sendData({
        messageType: "help",
        nickName: this.props.nickName,
      });
  }

  helpEnd() {
    if (this.props.skyway)
      this.props.skyway.sendData({
        messageType: "endHelp",
        nickName: this.props.nickName,
      });
  }

  render() {
    if (this.state.gotoPreview) {
      return <Redirect to='/preview/' />
    }
    return (
      <div className='EditorPageContainer'>
        <div ref={this._page} className='EditorPage'>
          <AppHeader />
          <EditorMain
            currentTimeMillisec={this.state.currentTimeMillisec}
            startTimeline={() => this._footer.current.playTimeline()}
            backToZero={() => this._footer.current.backToZero()}
            pauseProgress={() => this._footer.current.pauseProgress()}
            requestHelp={() => {
              if (this.props.skyway) {
                this.props.skyway.sendData({
                  messageType: 'help',
                  nickName: this.props.nickName
                })
              }
            }}
            requestHelpEnd={() => {
              if (this.props.skyway) {
                this.props.skyway.sendData({
                  messageType: 'helpEnd',
                  nickName: this.props.nickName
                })
              }
            }}
            ref={this._editor}
            chara={this.state.chara}
            scene={this.state.scene}
            startTime='0'
            isChooseMode={true}
            isEditMode={false}
            helpReq={this.helpReq}
            helpEnd={this.helpEnd}
            setAnimationJSON={this.setAnimation}
          />
          <AppFooter
            appendSVG={(id, delay) => this._editor.current.appendSVG(id, delay)}
            appendSound={(id, delay) => this._editor.current.appendSound(id, delay)}
            changeTime={(time_ms) => this.setState({ currentTimeMillisec: time_ms })}
            changeTimeWithAnimControl={(time_ms) => this._editor.current.changeTime(time_ms)}
            pusePreview={() => this._editor.current?.pause()}
            playPreview={() => this._editor.current?.play()}
            changeDelay={(id, delaytime) => this._editor.current.changeDelay(id, delaytime)}
            changeSoundDelay={(id, delay_ms) => this._editor.current.changeSoundDelay(id, delay_ms)}
            ref={this._footer}
            isChooseMode={true}
            isEditMode={false}
            chara={this.state.chara}
            scene={this.state.scene}
            setStartTime={(ms) => this.props.setStartTime(ms)}
            topMargin={this.state.topMargin}
            leftMargin={this.state.leftMargin}
          />
          {this.state.isBeingHelped && <ZvCursor x={this.state.zvCursorX} y={this.state.zvCursorY} />}
        </div>
      </div>
    );
  }
}

export default withRouter(ChoosePage);

import React, { Component } from 'react';
import './ChooseBGMButton.css'
class ChooseBGMButton extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        labelNo: props.no,
        selected: props.selected
    }
  }
  render() {
    return (
      <div
        className={`ChooseBGMButton ${this.props.selected ? "choose": ""}`}
      >
          <div>BGM {this.state.labelNo}</div>
      </div>
    );
  }
}

export default ChooseBGMButton;

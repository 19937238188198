import React, { Component } from "react";
import "./ChooseScenePage.css";
import { Redirect, withRouter } from "react-router-dom";
import Db, { MOVE_PAGE, CHOSEN_SCENES, CLIENT_SCENE } from "../helper/Db";
import Routes from "../helper/Routes";

class ChooseScenePage extends Component {
  constructor(props) {
    super(props);
    let chara = this.props.chara;

    if (!chara) {
      // fallback vita animation if individually test.
      const params = new URLSearchParams(window.location.search);
      chara = params.get("chara");
      chara = chara || "vita";
    }
    this.state = {
      chara: chara,
      charaFirstLetter: chara.substr(0, 1).toUpperCase(),
      gotoScreening: false,
    };
    this.scene1 = React.createRef(null);
    this.scene2 = React.createRef(null);
    this.scene3 = React.createRef(null);
    this.scene4 = React.createRef(null);
    this.scene5 = React.createRef(null);
    this.scene6 = React.createRef(null);
    this.sceneImages = [
      this.scene1,
      this.scene2,
      this.scene3,
      this.scene4,
      this.scene5,
      this.scene6,
    ];

    this.userChooseScenes = new Map();

    if (this.props.skyway) {
      this.props.skyway.addReceiveHandler(
        {
          receive: (e) => {
            console.log(e);
            let type = e.data.messageType;
            switch (type) {
              case "chosenScene":
                const chosenScenes = [];
                let scene = undefined;
                // scene no start from 1. not zero. (E.g., 1-6)
                if (!e.data.sceneId) {
                  // clear the scene from ZV.
                  // remove from managed user scenes.
                  this.userChooseScenes.delete(e.data.nickName);
                } else {
                  scene = parseInt(e.data.sceneId);
                  console.log(scene);
                  this.userChooseScenes.set(e.data.nickName, scene);
                  if (scene < 1 || scene > 6) {
                    // Illegal scene no.
                    this.userChooseScenes.delete(e.data.nickName);
                  }
                }

                this.userChooseScenes.forEach((sceneId, nickName) => {
                  chosenScenes.push({ sceneId, nickName });
                });

                // set grayscale
                const chooseScene = Array.from(this.userChooseScenes.values());
                this.sceneImages.forEach((img, i) => {
                  let no = i + 1;
                  if (chooseScene.includes(no)) {
                    img.current.style.filter = "grayscale(100%)";
                  } else {
                    img.current.style.filter = "none";
                  }
                });

                if (e.data.nickName === this.props.nickName && !!scene) {
                  // my choice, set the chosen scene for after process.
                  this.props.setChooseScene(scene);
                  Db(CLIENT_SCENE, scene);
                }
                Db(CHOSEN_SCENES, chosenScenes);
                break;
              case "movePage":
                let target = Routes(e.data.target);
                this.props.history.push(target);
                Db(MOVE_PAGE, target);
                break;
              case "screeningMovies":
                let screeningAnim = e.data.animations;
                console.log(JSON.stringify(screeningAnim));
                this.props.setScreeningData(screeningAnim);

                this.props.history.push(Routes("screening"));
                Db(MOVE_PAGE, target);
                break;
              default:
                break;
            }
          },
          error: (e) => {
            console.log(e);
          },
        },
        "choose-scene"
      );
    } else {
      this.props.history.push("/login");
    }
  }

  async componentDidMount() {
    const scene = await Db(CLIENT_SCENE);
    if (scene) {
      this.props.setChooseScene(scene);
    }

    const scenes = await Db(CHOSEN_SCENES);
    if (scenes) {
      const interval = setInterval(() => {
        let clear = true;
        scenes.forEach((x) => {
          const sceneId = x.sceneId;
          const nickName = x.nickName;
          this.userChooseScenes.set(nickName, sceneId);
          const ref = this.sceneImages[sceneId - 1];
          if (ref.current) {
            ref.current.style.filter = "grayscale(100%)";
          } else {
            clear = false;
          }
        });
        if (clear) clearInterval(interval);
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.props.skyway) this.props.skyway.removeReceiveHandler("choose-scene");
  }

  render() {
    if (this.state.gotoScreening) {
      return <Redirect to="/screening/"></Redirect>;
    }
    return (
      <div className="choose-secene-container">
        <div className="choose-scene-main">
          <div id="title">
            <img src={"/choose-scene/" + this.state.chara + "_title.svg"} alt="chara title" />
          </div>
          <div id="scene">
            <div>
              <img src={"/choose-scene/" + this.state.chara + "_1.svg"} alt="chara 1 title" />
              <img
                src={
                  "/choose-scene/" +
                  this.state.charaFirstLetter +
                  "1/" +
                  this.state.charaFirstLetter +
                  "1.gif"
                }
                alt="chara 1 anim"
                ref={this.scene1}
              />
            </div>
            <div>
              <img src={"/choose-scene/" + this.state.chara + "_2.svg"} alt="chara 2 title" />
              <img
                src={
                  "/choose-scene/" +
                  this.state.charaFirstLetter +
                  "2/" +
                  this.state.charaFirstLetter +
                  "2.gif"
                }
                alt="chara 2 anim"
                ref={this.scene2}
              />
            </div>
            <div>
              <img src={"/choose-scene/" + this.state.chara + "_3.svg"} alt="chara 3 title" />
              <img
                src={
                  "/choose-scene/" +
                  this.state.charaFirstLetter +
                  "3/" +
                  this.state.charaFirstLetter +
                  "3.gif"
                }
                alt="chara 3 anim"
                ref={this.scene3}
              />
            </div>
            <div>
              <img src={"/choose-scene/" + this.state.chara + "_4.svg"} alt="chara 4 title" />
              <img
                src={
                  "/choose-scene/" +
                  this.state.charaFirstLetter +
                  "4/" +
                  this.state.charaFirstLetter +
                  "4.gif"
                }
                alt="chara 4 anim"
                ref={this.scene4}
              />
            </div>
            <div>
              <img src={"/choose-scene/" + this.state.chara + "_5.svg"} alt="chara 5 title" />
              <img
                src={
                  "/choose-scene/" +
                  this.state.charaFirstLetter +
                  "5/" +
                  this.state.charaFirstLetter +
                  "5.gif"
                }
                alt="chara 5 anim"
                ref={this.scene5}
              />
            </div>
            <div>
              <img src={"/choose-scene/" + this.state.chara + "_6.svg"} alt="chara 6 title" />
              <img
                src={
                  "/choose-scene/" +
                  this.state.charaFirstLetter +
                  "6/" +
                  this.state.charaFirstLetter +
                  "6.gif"
                }
                alt="chara 6 anim"
                ref={this.scene6}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ChooseScenePage);

import React, { Component } from 'react';
import './ChooseBGM.css';
import './ScreeningPage.css'
import ScreeningPlayer from "../component/player/ScreeningPlayer.js"
import { URBANO, URBANO_ONE, VITA, VITA_ONE, BACHE, BACHE_ONE } from '../component/player/sample.js'

class ChooseBGM extends Component {
  constructor(props) {
    super(props);

    let chara = this.json?.character || this.props.chara || "B";
    let scene = this.json?.scene || this.props.scene || "2";
    let nickname = this.json?.nickName || this.props.nickName || "test";
    let start = this.json?.start || this.props.startTime || 15*1000;
    this.state = {
      chara,
      scene,
      nickname,
      start,
      gotoEdit: false,
    }

    this.player = React.createRef();
  }

  componentDidMount() {
    // console.log(this.props.json.room_1.content);
    if (this.props.skyway) {
      this.props.skyway.addReceiveHandler(
        {
          receive: (e) => {
            let type = e.data.messageType;
            switch (type) {
              case "queue":
                this.player.current.import(this.props.json[e.data.id].content);
                break;
              case "pauseScreening":
                if (this.player.current) {
                  this.player.current.pause()
                }
                break;
              case "resumeScreening":
                if (this.player.current) {
                  this.player.current.resume()
                }
                break;
              case "screeningMovies":
                let screeningAnim = e.data.animations;
                console.log(JSON.stringify(screeningAnim));
                this.props.setScreeningData(screeningAnim);
                break;
              default:
                break;
            }
          },
          erro: (e) => {},
        },
        "screening"
      );
      // 映像アップロード前でも一度は映像取得を試みる
      // 復旧時にこのコードが有効に働き、復旧以外では無駄な通信になるが副作用は無いコードとなる
      // SV で受信後、現在のルームに保存されているアニメーションを返す。アニメーションがない場合は空オブジェクトとなる
      this.props.skyway.sendData({
        messageType: 'requestScreeningMovie'
      })
    }
  }

  componentWillUnmount() {
    if (this.props.skyway) this.props.skyway.removeReceiveHandler("screening");
  }

  demonstration() {
    this.player.current.import(URBANO);
  }

  render() {
    return (
      <div className='ScreeningPage-container'>
        <div className='ScreeningPage' style={{ color: 'white' }}>
          <div id='screening-player-pane'>
            <ScreeningPlayer ref={this.player} volume={0.5} />
          </div>
          {process.env.REACT_APP_ENV === 'dev' && (
            <div>
              <button onClick={() => { this.player.current.import(URBANO_ONE) }}>
                urbano-1
              </button>
              <button onClick={() => { this.player.current.import(URBANO) }}>
                urbano-4
              </button>
              <button onClick={() => { this.player.current.import(VITA_ONE) }}>
                vita-1
              </button>
              <button onClick={() => { this.player.current.import(VITA) }}>
              vita-4
              </button>
              <button onClick={() => { this.player.current.import(BACHE_ONE) }}>
                bache-1
              </button>
              <button onClick={() => { this.player.current.import(BACHE) }}>
              bache-4
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ChooseBGM;


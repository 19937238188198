import React, { Component } from 'react'
import './WatchPage.css'
import './ScreeningPage.css'
import ScreeningPlayer from '../component/player/ScreeningPlayer.js'
import { URBANO, URBANO_ONE, VITA, VITA_ONE, BACHE, BACHE_ONE } from '../component/player/sample.js'
import { v4 as uuidv4 } from 'uuid'
import Redirect from "../pages/Redirect.js";

class WatchPage extends Component {
  constructor(props) {
    super(props)

    const params = new URLSearchParams(window.location.search)
    const chara = params.get('chara')
    const date = params.get('date')
    const time = params.get('time')
    const dateLable = `${date.substr(0,4)}.${parseInt(date.substr(4,2))}.${parseInt(date.substr(6,2))}`
    this.state = {
      chara,
      date,
      time,
      dateLable,
      redirectToOnlineKidzania: false,
      displayPlayButton: false,
    }

    this.player = React.createRef()
  }

  componentDidMount() {
    this.props.removeUnloadHandler()

    if (!this.state.chara || !this.state.date || !this.state.time) {
      this.setState({
        redirectToOnlineKidzania: true
      })
      return
    }

    setTimeout(async () => {
      this.lazyLoadingJSON()
    }, 50)
  }

  componentWillUnmount() {
  }

  async lazyLoadingJSON() {
    const token = uuidv4()
    // date=20210410&time=0815&chara=vita
    // 2021_04_01_1530_vita.json
    //https://firebasestorage.googleapis.com/v0/b/kz-movie-creator-dev2.appspot.com/o/2021_04_01_1530_bache.json?alt=media&token=10d590d1-9729-4548-9811-591cf82b3d03
    const dateParam = `${[this.state.date.slice(0, 4), this.state.date.slice(4,6), this.state.date.slice(6,8)].join('_')}`
    const url = `${process.env.REACT_APP_STORAGE_ENDPOINT}${dateParam}_${this.state.time}_${this.state.chara}.json?alt=media&token=${token}`
    console.log(url)

    try {
      const response = await fetch(url)
      const json = await response.json()
      console.log(json)
      const date = `${json.room_year}.${json.room_month}.${json.room_date}`
      this.player.current.import(json.content, date)
    } catch(e) {
      console.log(e)
      this.setState({
        redirectToOnlineKidzania: true
      })
    }
  }

  demonstration() {
    this.player.current.import(URBANO)
  }

  render() {
    if (this.state.redirectToOnlineKidzania) {
      return (<Redirect loc={process.env.REACT_APP_REDIRECT_URL}></Redirect>)
    }
    return (
      <div className='ScreeningPage-container'>
        <div className='ScreeningPage' style={{ color: 'white' }}>
          <div id='screening-player-pane'>
            <ScreeningPlayer
              ref={this.player}
              volume={0.5}
              paused={true}
              importLoad={() => {
                // view start button
                this.setState({
                  displayPlayButton: true,
                })
              }}
              finishAnimation={() => {
                this.setState({
                  displayPlayButton: true,
                })
              }}
              />
              {this.state.displayPlayButton && (
                <div
                  id='play-button'
                  onClick={() => {
                    this.setState({
                      displayPlayButton: false,
                    })
                    this.player.current.pauseWithZero()
                    this.player.current.clearSoundsPlayHistory()
                    this.player.current.play()
                  }}
                >
                  <img src='/preview/play_button.svg' alt='play'></img>
                </div>
              )}
          </div>
          {process.env.REACT_APP_ENV === 'dev' && (
            <div>
              <button onClick={() => { this.player.current.import(URBANO_ONE) }}>
                urbano-1
              </button>
              <button onClick={() => { this.player.current.import(URBANO) }}>
                urbano-4
              </button>
              <button onClick={() => { this.player.current.import(VITA_ONE) }}>
                vita-1
              </button>
              <button onClick={() => { this.player.current.import(VITA) }}>
              vita-4
              </button>
              <button onClick={() => { this.player.current.import(BACHE_ONE) }}>
                bache-1
              </button>
              <button onClick={() => { this.player.current.import(BACHE) }}>
              bache-4
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default WatchPage


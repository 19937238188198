import dayjs from 'dayjs'

export class AnimationStudioAPI {
    async isActiveRoom(roomId) {
        const now = dayjs();
        let year = now.get('year');
        let month = now.get('month') + 1;
        let date = now.get('date');
        let time = now.format('HH:mm')
        let url =
          `${process.env.REACT_APP_WEB_API_ENDPOINT}/isActiveRoom?room_id=${roomId}&year=${year}&month=${month}&date=${date}&time=${time}`;

        try {
            let response = await fetch(url);
            let data = await response.json();

            if (!data.isActive) {
                console.log('specified room id is inactive.');
                if (process.env.REACT_APP_ENV === 'dev') return true;
            }
            return data.isActive;
        } catch(e) {
            return false;
        }
    }
}
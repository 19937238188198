import React, { Component } from "react";
import "./EditorMain.css";
import EffectPane from "./EffectPane.js";
import PreviewPane from "./PreviewPane.js";

class EditorMain extends Component {
  constructor(props) {
    super(props);
    this._previewPane = React.createRef();
    this.state = {
      chara: props.chara,
      scene: props.scene,
      start: props.startTime,
      isChooseMode: props.isChooseMode,
      isEditMode: props.isEditMode,
      reqHelping: false,
    };
  }

  play() {
    this._previewPane.current.play();
  }

  pause() {
    this._previewPane.current.pause();
  }

  appendSVG(id, delay = 0) {
    this._previewPane.current.appendSVG(id, delay);
  }

  removeSVG(id) {
    this._previewPane.current.removeSVG(id);
  }

  appendSound(id, delay = 0) {
    this._previewPane.current.appendSound(id, delay);
  }

  removeSound(id) {
    this._previewPane.current.removeSound(id);
  }

  changeDelay(id, delaytime) {
    this._previewPane.current.changeDelay(id, delaytime);
  }

  changeSoundDelay(id, delay_ms) {
    this._previewPane.current.changeSoundDelay(id, delay_ms);
  }

  changeTime(timeMillisec) {
    this._previewPane.current.changeTime(timeMillisec);
  }

  helpEnd() {
    if (this.state.reqHelping) {
      this.props.requestHelpEnd();
    }
    this.setState({
      reqHelping: false,
    });
  }

  render() {
    return (
      <div id="editor-main">
        <div id="main">
          <ul className="timeline">
            <li>
              <div className="direction-r">
                <div className="flag-wrapper">
                  <span className="hexa"></span>
                  {this.state.isChooseMode ? (
                    <span className="flag baloom">Step 1</span>
                  ) : (
                    <span className="flag">Step 1</span>
                  )}
                </div>
                <div>
                  {this.state.isChooseMode ? (
                    <object
                      type="image/svg+xml"
                      data="/edit/step1_announce-active.svg"
                      aria-label="step1"
                    ></object>
                  ) : (
                    <object
                      type="image/svg+xml"
                      data="/edit/step1_announce.svg"
                      aria-label="step1"
                    ></object>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="direction-r current">
                <div className="flag-wrapper">
                  <span className="hexa"></span>
                  {this.state.isEditMode ? (
                    <span className="flag baloom">Step 2</span>
                  ) : (
                    <span className="flag">Step 2</span>
                  )}
                </div>
                <div>
                  {this.state.isEditMode ? (
                    <object
                      type="image/svg+xml"
                      data="/edit/step2_announce-active.svg"
                      aria-label="step2"
                    ></object>
                  ) : (
                    <object
                      type="image/svg+xml"
                      data="/edit/step2_announce.svg"
                      aria-label="step2"
                    ></object>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="direction-r last-element">
                <div className="flag-wrapper">
                  <span className="hexa"></span>
                  {this.state.isPreviewMode ? (
                    <span className="flag baloom">Step 3</span>
                  ) : (
                    <span className="flag">Step 3</span>
                  )}
                </div>
                <div>
                  {this.state.isPreviewMode ? (
                    <object
                      type="image/svg+xml"
                      data="/edit/step3_announce-active.svg"
                      aria-label="step3"
                    ></object>
                  ) : (
                    <object
                      type="image/svg+xml"
                      data="/edit/step3_announce.svg"
                      aria-label="step3"
                    ></object>
                  )}
                </div>
              </div>
            </li>
          </ul>
          <div id="helpButton">
            <img
              src={this.state.reqHelping ? `/HelpRevert.svg` : `/Help.svg`}
              alt="help svg"
              onClick={() => {
                if (this.state.reqHelping) {
                  this.props.requestHelpEnd();
                  this.setState({
                    reqHelping: false,
                  });
                } else {
                  this.props.requestHelp();
                  this.setState({
                    reqHelping: true,
                  });
                }
              }}
            ></img>
            <p className='tooltiptext'>ヘルプ</p>
          </div>
        </div>
        <PreviewPane
          currentTimeMillisec={this.props.currentTimeMillisec}
          startTimeline={this.props.startTimeline}
          backToZero={this.props.backToZero}
          pauseProgress={this.props.pauseProgress}
          import={this.props.import}
          json={this.props.json}
          setAnimationJSON={(json) => {
            if (this.props.setAnimationJSON) {
              this.props.setAnimationJSON(json);
            }
          }}
          ref={this._previewPane}
          chara={this.state.chara}
          scene={this.state.scene}
          start={this.state.start}
          isChooseMode={this.state.isChooseMode}
        />
        {!this.state.isChooseMode && <EffectPane />}
      </div>
    );
  }
}

export default EditorMain;

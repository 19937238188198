
import React, { Component } from 'react';
import './Player.css';

const TICK_MS = 50;

// 10 秒のプレビュー用コンポーネント
class Player extends Component {
  constructor(props) {
    super(props)
    this.animDivRef = React.createRef()
    this.audio1Ref = React.createRef()
    this.audio2Ref = React.createRef()
    this.audio3Ref = React.createRef()
    this.audio4Ref = React.createRef()
    this.audio5Ref = React.createRef()
    this.audio6Ref = React.createRef()
    this.audioElemRefs = [
      this.audio1Ref,
      this.audio2Ref,
      this.audio3Ref,
      this.audio4Ref,
      this.audio5Ref,
      this.audio6Ref
    ]

    let chara = this.props.char
    if (!chara) {
      chara = 'U'
    }
    let scene = this.props.scene
    if (!scene) {
      scene = '1'
    }
    let start = this.props.start
    if (!start) {
      start = 0
    }

    this.initScene(chara, scene)
    this.state = {
      baseStartTimeMillisec: start,
      baseCharacterType: chara, // V=Vita U=Urbano B=Bache
      baseSceneNo: scene, // 1~6
      svgTelops: this.svgTelops,
      svgTextEffects1: this.svgTextEffects1,
      svgVisualEffects1: this.svgVisualEffects1,
      svgTextEffects2: this.svgTextEffects2,
      svgVisualEffects2: this.svgVisualEffects2,
      svgCharacter: this.svgCharacter,
      svgTextEffects3: this.svgTextEffects3,
      svgVisualEffects3: this.svgVisualEffects3,
      svgBackground: this.svgBackground,
      isPlaying: false,
      soundElements: [], // [{soundId, delay, targetId, isPlayed}..]
      maxAnmiationDurationMS: 10 * 1000
    }
    this.isPlaying = false
    this.startPlayingTimeMS = 0
    this.animationObserverHandler = undefined
    this.animationObserver = this.animationObserver.bind(this)
    this.playWithKeyHandler = this.playWithKeyHandler.bind(this)
  }

  initScene(chara, scene) {
    const fileBaseName = `/movies/${chara}${scene}-`
    const charaFileName = `${fileBaseName}6-C.svg`
    const backFilename = `${fileBaseName}9-B.svg`
    const telopFileName = `${fileBaseName}0-T.svg`

    // 内部ステート変更検知して描画するために必要。
    this.svgTelops = [<iframe id='telopSVG' src={telopFileName} title='telopSVG'></iframe>]
    this.svgTextEffects1 = []
    this.svgVisualEffects1 = []
    this.svgTextEffects2 = []
    this.svgVisualEffects2 = []
    this.svgCharacter = [
      <iframe
        id='charaSVG'
        title='charaSVG'
        onLoad={() => this.changeDelay('charaSVG', -this.state.baseStartTimeMillisec)}
        src={charaFileName}
      />
    ]
    this.svgTextEffects3 = []
    this.svgVisualEffects3 = []
    this.svgBackground = [
      <iframe
        id='backSVG'
        title='backSVG'
        onLoad={() => this.changeDelay('backSVG', -this.state.baseStartTimeMillisec)}
        src={backFilename}
      />
    ]
  }

  playWithKeyHandler(e) {
    if (e.code !== 'Space') return
    if (this.isPlaying) {
      this.pause()
    } else {
      this.play()
    }
  }

  componentDidMount() {
    window.addEventListener('keyup', this.playWithKeyHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.playWithKeyHandler)
    if (this.animationObserverHandler) {
      window.clearInterval(this.animationObserverHandler)
    }
  }

  appendSVG(id, delay) {
    this.needInitPausing = true
    // id is '<type>-<name>-<sequence>'
    let svgId = id.substr(0, id.lastIndexOf('-'))
    let type = svgId.substr(0, svgId.indexOf('-'))
    let svgName = svgId.substr(svgId.indexOf('-') + 1)
    let svgFile
    switch (type) {
      case 'visual':
        svgFile = `/visual_effects/E-${svgName}.svg`
        if (svgName.startsWith('2')) {
          this.svgVisualEffects1.push(
            <iframe
              id={id}
              title={id}
              onLoad={() => this.changeDelay(id, delay)}
              type='image/svg+xml'
              aria-label='ve1'
              src={svgFile}
              key={id}
            />
          )
          this.setState({
            svgVisualEffects1: this.svgVisualEffects1
          })
        } else if (svgName.startsWith('5')) {
          this.svgVisualEffects2.push(
            <iframe
              id={id}
              title={id}
              onLoad={() => this.changeDelay(id, delay)}
              type='image/svg+xml'
              aria-label='ve1'
              src={svgFile}
              key={id}
            />
          )
          this.setState({
            svgVisualEffects2: this.svgVisualEffects2
          })
        }
        break
      case 'text':
        svgFile = `/text_effects/E-${svgName}.svg`
        if (svgName.startsWith('4')) {
          this.svgTextEffects2.push(
            <iframe
              id={id}
              title={id}
              onLoad={() => this.changeDelay(id, delay)}
              type='image/svg+xml'
              aria-label='ve1'
              src={svgFile}
              key={id}
            />
          )
          this.setState({
            svgTextEffects2: this.svgTextEffects2
          })
        } else if (svgName.startsWith('7')) {
          this.svgTextEffects3.push(
            <iframe
              id={id}
              title={id}
              onLoad={() => this.changeDelay(id, delay)}
              type='image/svg+xml'
              aria-label='ve1'
              src={svgFile}
              key={id}
            />
          )
          this.setState({
            svgTextEffects3: this.svgTextEffects3
          })
        }
        break
      default:
        break
    }
  }

  changeDelay(id, delayTime) {
    let div = this.animDivRef.current
    let targetObj = div.querySelector(`#${id}`)
    let anims = targetObj.getSVGDocument().getAnimations()
    for (var anim of anims) {
      let timing = anim.effect.getTiming()
      timing.delay = delayTime
      anim.effect.updateTiming(timing)
      anim.pause()
    }
  }

  appendSound(id, delay = 0) {
    let soundFactor = id.split('-')
    this.setState({
      soundElements: [
        ...this.state.soundElements,
        {
          id: soundFactor[1],
          delay,
          targetId: id,
          isPlayed: false
        }
      ]
    })
  }

  appendSounds(_soundEffects) {
    let random = window.crypto.getRandomValues(new Uint32Array(100))
    const ids = _soundEffects.map((effect, i) => effect.id + `-${random[i]}`)
    const delays = _soundEffects.map((effect) => effect.delay)
    const soundFactors = ids.map((id) => id.split('-')[1])
    const _soundElements = ids.map((id, index) => {
      return {
        id: soundFactors[index],
        delay: delays[index],
        targetId: id,
        isPlayed: false
      }
    })
    this.setState({
      soundElements: this.state.soundElements.concat(_soundElements)
    })
  }

  pause() {
    let div = this.animDivRef.current
    if (!div) {
      if (this.animationObserverHandler) {
        window.clearInterval(this.animationObserverHandler)
      }
      return
    }
    for (var obj of div.children) {
      let svg = obj.getSVGDocument()
      if (!svg) continue
      for (var anim of svg.getAnimations()) {
        anim.pause()
      }
    }
    this.isPlaying = false
    this.setState({
      isPlaying: this.isPlaying
    })
    this.clearSoundsPlayHistory()
    if (this.animationObserverHandler) {
      window.clearInterval(this.animationObserverHandler)
    }
  }

  play() {
    if (this.isPlaying) {
      this.pause()
      this.isPlaying = false
      this.setState({
        isPlaying: this.isPlaying
      })
      return
    }

    if (this.props.currentTimeMillisec >= this.state.maxAnmiationDurationMS) {
      this.pause()
      this.isPlaying = false
      this.setState({
        isPlaying: this.isPlaying
      })
      return
    }
    let div = this.animDivRef.current
    for (var obj of div.children) {
      console.log(obj)
      let svg = obj.getSVGDocument()
      for (var anim of svg.getAnimations()) {
        anim.currentTime = 0
        anim.play()
      }
    }
    this.isPlaying = true
    this.startPlayingTimeMS = Date.now()
    this.animationObserverHandler = window.setInterval(this.animationObserver, TICK_MS)
    this.setState({
      isPlaying: this.isPlaying
    })
  }

  animationObserver() {
    let duration = Date.now() - this.startPlayingTimeMS
    this.props?.updateCurrentTime(duration);
    if (duration > this.state.maxAnmiationDurationMS) {
      this.pause()
      if (this.props.endPlaying) {
        this.props.endPlaying()
      }
      return
    }
    // Sound control
    let needUpdate = false
    let updateArray = []
    this.state.soundElements.forEach((s) => {
      if (s.delay < duration && !s.isPlayed && !!this.audioElemRefs[parseInt(s.id) - 1].current) {
        this.audioElemRefs[parseInt(s.id) - 1].current.currentTime = 0
        this.audioElemRefs[parseInt(s.id) - 1].current.volume = 0.3
        this.audioElemRefs[parseInt(s.id) - 1].current.play()
        s.isPlayed = true
        updateArray.push(s)
        needUpdate = true
      } else {
        updateArray.push(s)
      }
    })
    if (needUpdate) {
      this.setState({
        soundElements: updateArray
      })
    }
  }

  clearSoundsPlayHistory() {
    let sounds = []
    this.state.soundElements.forEach((s) => {
      s.isPlayed = false
      sounds.push(s)
    })
    this.setState({
      soundElements: sounds
    })
  }

  import(jsonObject) {
    this.pause()
    this.initScene(jsonObject.character, jsonObject.scene)
    console.log('Player import:', jsonObject)

    this.setState({
      baseCharacterType: jsonObject.character,
      baseSceneNo: jsonObject.scene,
      baseStartTimeMillisec: jsonObject.start,
      svgTelops: this.svgTelops,
      svgTextEffects1: this.svgTextEffects1,
      svgVisualEffects1: this.svgVisualEffects1,
      svgTextEffects2: this.svgTextEffects2,
      svgVisualEffects2: this.svgVisualEffects2,
      svgCharacter: this.svgCharacter,
      svgTextEffects3: this.svgTextEffects3,
      svgVisualEffects3: this.svgVisualEffects3,
      svgBackground: this.svgBackground,
      isPlaying: false,
      soundElements: [] // [{soundId, delay, targetId, isPlayed}..]
    })

    let random = window.crypto.getRandomValues(new Uint32Array(100))

    const _animationEffects = jsonObject.effects.filter((effect) => effect.id !== '' && !effect.id.includes('sound'))
    _animationEffects.forEach((effect, i) => {
      this.appendSVG(effect.id + `-${random[i]}`, effect.delay)
    })

    const _soundEffects = jsonObject.effects.filter((effect) => effect.id !== '' && effect.id.includes('sound'))
    this.appendSounds(_soundEffects)
  }

  render() {
    return (
      <div id='player-pane'>
        <div>
          <div id='player-anim-pane' ref={this.animDivRef}>
            {this.svgBackground}
            {this.state.svgVisualEffects3.map((obj) => obj)}
            {this.svgTextEffects3.map((obj) => obj)}
            {this.svgCharacter}
            {this.state.svgVisualEffects2.map((obj) => obj)}
            {this.svgTextEffects2.map((obj) => obj)}
            {this.state.svgVisualEffects1.map((obj) => obj)}
            {this.svgTextEffects1.map((obj) => obj)}
            {this.svgTelops.map((obj) => obj)}
          </div>
        </div>
        <div id='player-audio-elems'>
          <audio id='sound1' controls src='/sound_effects/1.mp3' ref={this.audio1Ref} />
          <audio id='sound2' controls src='/sound_effects/2.mp3' ref={this.audio2Ref} />
          <audio id='sound3' controls src='/sound_effects/3.mp3' ref={this.audio3Ref} />
          <audio id='sound4' controls src='/sound_effects/4.mp3' ref={this.audio4Ref} />
          <audio id='sound5' controls src='/sound_effects/5.mp3' ref={this.audio5Ref} />
          <audio id='sound6' controls src='/sound_effects/6.mp3' ref={this.audio6Ref} />
        </div>
      </div>
    )
  }
}

export default Player;

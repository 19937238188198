export async function getOSVersion(uaParserObj)  {
    const osName = uaParserObj.getOS().name;
    const osVersion = uaParserObj.getOS().version;
    if (osName && osName.includes('Windows')) {
      // https://docs.microsoft.com/en-us/microsoft-edge/web-platform/how-to-detect-win11
      const ua = await navigator.userAgentData.getHighEntropyValues(["platformVersion"]);
      const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0]);
      if (majorPlatformVersion >= 13) {
        // 2021/10 時点で Windows 11 以降の発売が予定されていないので、Windows 11 と指定する
        return 11;
      } else if (majorPlatformVersion > 0) {
        return 10;
      } else {
        return osVersion;
      }
    } else {
      return new Promise((resolve, _ ) => {
        resolve(osVersion);
      });
    }
};
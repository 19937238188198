import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import "./ChooseBGM.css";
import ScreeningPlayer from "../component/player/ScreeningPlayer.js";
import ChooseBGMButton from "../component/ChooseBGMButton.js";
import Db, { MOVE_PAGE } from "../helper/Db";

class ChooseBGM extends Component {
  constructor(props) {
    super(props);

    let chara = this.json?.character || this.props.chara || "B";
    let scene = this.json?.scene || this.props.scene || "2";
    let nickname = this.json?.nickName || this.props.nickName || "test";
    let start = this.json?.start || this.props.startTime || 15 * 1000;
    this.state = {
      chara,
      scene,
      nickname,
      start,
      gotoEdit: false,
      no1Selected: false,
      no2Selected: false,
      no3Selected: false
    }

    this.player = React.createRef();
  }

  componentDidMount() {
    if (!this.props.skyway) {
      this.props.history.push("/login");
      return;
    }

    if (this.player.current) {
      if (this.props.json) {
        this.player.current.import(this.props.json);
      }
    }
    if (this.props.skyway) {
      this.props.skyway.addReceiveHandler(
        {
          receive: (e) => {
            let type = e.data.messageType;
            switch (type) {
              case "decideBGM":
                let json = e.data.json;
                console.log(JSON.stringify(json));
                this.player.current.import(json);
                this.setState({
                  no1Selected: json.bgm === 1 ? true : false,
                  no2Selected: json.bgm === 2 ? true : false,
                  no3Selected: json.bgm === 3 ? true : false,
                });
                break;
              case "pauseBGM":
                if (this.player.current) {
                  this.player.current.pause()
                }
                break;
              case "resumeBGM":
                if (this.player.current) {
                  this.player.current.resume()
                }
                break;
              case "movePage":
                let target = e.data.target;
                if (target === 'screening') {
                  this.player.current.pause();
                  Db(MOVE_PAGE, "/screening/");
                  this.setState({
                    gotoScreening: true
                  });
                  this.gotoScreening()
                }
                break;
              default:
                break;
            }
          },
          erro: (e) => {},
        }, "bgm")
    }
  }

  componentWillUnmount() {
    if (this.props.skyway) this.props.skyway.removeReceiveHandler("bgm");
  }

  gotoScreening() {
    console.log("goto screening.");
    this.props.history.push("/screening/");
  }

  render() {
    if (this.state.gotoScreening) {
      return <Redirect to="/screening/" />;
    }
    return (
      <div className='choose-bgm-container'>
        <div className='ChooseBGM' style={{ color: 'white' }}>
          <div id='screening-player-pane'>
            <ScreeningPlayer ref={this.player} volume={0.3} />
            <div id='bgm-area'>
              <ChooseBGMButton no='1' selected={this.state.no1Selected} />
              <ChooseBGMButton no='2' selected={this.state.no2Selected} />
              <ChooseBGMButton no='3' selected={this.state.no3Selected} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ChooseBGM);

import React, { Component } from "react";
import "./ScreenMovement.css";
import { withRouter } from "react-router-dom";
import Db, { MOVE_PAGE } from "../../helper/Db";

class ScreenMovement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gotoEdit: false,
      gotoPreview: false,
    };
  }

  decideStartTime() {
    console.log(this.props.getCurrentTrimeSec());
    this.props.setStartTime(this.props.getCurrentTrimeSec());

    this.props.history.push("/edit/");
    Db(MOVE_PAGE, "/edit/");
  }

  gotoPreview() {
    console.log("preview");
    this.props.pauseProgress();

    this.props.history.push("/preview/");
    Db(MOVE_PAGE, "/preview/");
  }

  render() {
    if (this.props.isChooseMode) {
      return (
        <div id="screen-movement-main-choice">
          <img
            src="/nextbutton.svg"
            alt="next"
            onClick={() => {
              this.decideStartTime();
            }}
          />
        </div>
      );
    }
    return (
      <div id="screen-movement-main">
        <img
          className="button-step2"
          src="/back_step1.svg"
          alt="back to step1"
          onClick={() => {
            this.props.confirmBacktoStep1();
          }}
        ></img>
        <img
          className="button-step2"
          src="/nextbutton.svg"
          alt="next"
          onClick={() => {
            this.gotoPreview();
          }}
        ></img>
      </div>
    );
  }
}

export default withRouter(ScreenMovement);

export const URBANO = {
    "bgm": 3,
    "animations": [
      {"character":"U","scene":"1","start":"1000","nickName":"たろう","effects":[{"id":"text-4-no","delay":3740.740740740741},{"id":"visual-5-light","delay":74.07407407407408},{"id":"visual-5-moya","delay":7283.950617283951},{"id":"visual-5-sweat","delay":2395.061728395062},{"id":"sound-5","delay":7296.2962962962965},{"id":"sound-4","delay":111.1111111111111},{"id":"sound-6","delay":3765.432098765432}]},
      {"character":"U","scene":"2","start":"18000","nickName":"ハナコ","effects":[{"id":"text-4-yes","delay":7209.876543209876},{"id":"text-7-yummy","delay":49.382716049382715},{"id":"visual-5-light","delay":4703.7037037037035},{"id":"visual-2-kira","delay":8518.518518518518},{"id":"visual-2-music","delay":1950.6172839506173},{"id":"sound-1","delay":7172.83950617284},{"id":"sound-4","delay":6037.037037037037}]},
      {"character":"U","scene":"4","start":"5000","nickName":"テンプレート1","effects":[{"id":"text-4-wow","delay":4246.913580246914},{"id":"text-7-cool","delay":839.5061728395061},{"id":"visual-2-kira","delay":6271.6049382716055},{"id":"visual-2-kira","delay":8617.283950617282},{"id":"visual-2-star","delay":0},{"id":"sound-2","delay":3604.938271604938}]},
      {"character":"U","scene":"5","start":"20000","nickName":"Tony","effects":[{"id":"text-7-cool","delay":703.7037037037037},{"id":"text-7-friend","delay":7753.086419753086},{"id":"text-7-fun","delay":4123.456790123457},{"id":"visual-2-kira","delay":4580.246913580247},{"id":"sound-3","delay":1024.6913580246915},{"id":"sound-3","delay":3481.4814814814813},{"id":"sound-2","delay":7753.086419753086}]}
    ]};
export const URBANO_ONE = {
  "bgm": 3,
  "animations": [
    {"character":"U","scene":"1","start":"1000","nickName":"テンプレート3","effects":[{"id":"text-4-no","delay":3740.740740740741},{"id":"visual-5-light","delay":74.07407407407408},{"id":"visual-5-moya","delay":7283.950617283951},{"id":"visual-5-sweat","delay":2395.061728395062},{"id":"sound-5","delay":7296.2962962962965},{"id":"sound-4","delay":111.1111111111111},{"id":"sound-6","delay":3765.432098765432}]},
    {"character":"U","scene":"2","start":"18000","nickName":"テンプレート１","effects":[{"id":"text-4-yes","delay":7209.876543209876},{"id":"text-7-yummy","delay":49.382716049382715},{"id":"visual-5-light","delay":4703.7037037037035},{"id":"visual-2-kira","delay":8518.518518518518},{"id":"visual-2-music","delay":1950.6172839506173},{"id":"sound-1","delay":7172.83950617284},{"id":"sound-4","delay":6037.037037037037}]},
    {"character":"U","scene":"4","start":"5000","nickName":"たろう","effects":[{"id":"text-4-wow","delay":4246.913580246914},{"id":"text-7-cool","delay":839.5061728395061},{"id":"visual-2-kira","delay":6271.6049382716055},{"id":"visual-2-kira","delay":8617.283950617282},{"id":"visual-2-star","delay":0},{"id":"sound-2","delay":3604.938271604938}]},
    {"character":"U","scene":"5","start":"20000","nickName":"テンプレート2","effects":[{"id":"text-7-cool","delay":703.7037037037037},{"id":"text-7-friend","delay":7753.086419753086},{"id":"text-7-fun","delay":4123.456790123457},{"id":"visual-2-kira","delay":4580.246913580247},{"id":"sound-3","delay":1024.6913580246915},{"id":"sound-3","delay":3481.4814814814813},{"id":"sound-2","delay":7753.086419753086}]}
  ]};
export const VITA= {
  "bgm": 1,
  "animations": [
    {"character":"V","scene":"1","start":"0","nickName":"パンダ","effects":[{"id":"text-7-love","delay":2172.8395061728393},{"id":"visual-2-flower","delay":592.5925925925926},{"id":"visual-5-party","delay":7493.827160493827},{"id":"visual-5-heart","delay":4555.555555555556},{"id":"sound-1","delay":592.5925925925926},{"id":"sound-3","delay":7506.172839506173}]},
    {"character":"V","scene":"2","start":"19000","nickName":"カピバラ","effects":[{"id":"text-4-kai","delay":5716.049382716049},{"id":"text-7-fun","delay":1086.4197530864196},{"id":"visual-5-light","delay":1481.4814814814813},{"id":"sound-4","delay":4111.11111111111},{"id":"sound-1","delay":5716.049382716049}]},
    {"character":"V","scene":"3","start":"19700","nickName":"テンプレート1","effects":[{"id":"text-7-hmmm","delay":4370.37037037037},{"id":"text-7-love","delay":7814.814814814815},{"id":"visual-5-moya","delay":802.4691358024692},{"id":"visual-5-party","delay":7802.469135802469},{"id":"sound-6","delay":4395.061728395061},{"id":"sound-4","delay":7802.469135802469},{"id":"sound-3","delay":8901.234567901234}]},
    {"character":"V","scene":"4","start":"1300","nickName":"タイガー","effects":[{"id":"text-7-cool","delay":4975.308641975308},{"id":"text-7-love","delay":7864.197530864198},{"id":"visual-2-flower","delay":1444.4444444444443},{"id":"visual-2-kira","delay":4975.308641975308},{"id":"sound-1","delay":938.2716049382716},{"id":"sound-3","delay":8901.234567901234}]}
  ]};
export const VITA_ONE= {
  "bgm": 1,
  "animations": [
    {"character":"V","scene":"1","start":"0","nickName":"テンプレート１","effects":[{"id":"text-7-love","delay":2172.8395061728393},{"id":"visual-2-flower","delay":592.5925925925926},{"id":"visual-5-party","delay":7493.827160493827},{"id":"visual-5-heart","delay":4555.555555555556},{"id":"sound-1","delay":592.5925925925926},{"id":"sound-3","delay":7506.172839506173}]},
    {"character":"V","scene":"2","start":"19000","nickName":"ハナコ","effects":[{"id":"text-4-kai","delay":5716.049382716049},{"id":"text-7-fun","delay":1086.4197530864196},{"id":"visual-5-light","delay":1481.4814814814813},{"id":"sound-4","delay":4111.11111111111},{"id":"sound-1","delay":5716.049382716049}]},
    {"character":"V","scene":"3","start":"19700","nickName":"テンプレート2","effects":[{"id":"text-7-hmmm","delay":4370.37037037037},{"id":"text-7-love","delay":7814.814814814815},{"id":"visual-5-moya","delay":802.4691358024692},{"id":"visual-5-party","delay":7802.469135802469},{"id":"sound-6","delay":4395.061728395061},{"id":"sound-4","delay":7802.469135802469},{"id":"sound-3","delay":8901.234567901234}]},
    {"character":"V","scene":"4","start":"1300","nickName":"テンプレート2","effects":[{"id":"text-7-cool","delay":4975.308641975308},{"id":"text-7-love","delay":7864.197530864198},{"id":"visual-2-flower","delay":1444.4444444444443},{"id":"visual-2-kira","delay":4975.308641975308},{"id":"sound-1","delay":938.2716049382716},{"id":"sound-3","delay":8901.234567901234}]}
  ]};

export const BACHE= {
  "bgm": 1,
  "animations": [
    {"character":"B","scene":"1","start":"0","nickName":"たろう","effects":[{"id":"text-7-fun","delay":2197.5308641975307},{"id":"visual-5-cracker","delay":2209.8765432098767},{"id":"visual-2-kira","delay":8395.061728395061},{"id":"visual-5-sweat","delay":4469.135802469136},{"id":"sound-6","delay":4444.444444444444},{"id":"sound-3","delay":2209.8765432098767},{"id":"sound-2","delay":7814.814814814815}]},
    {"character":"B","scene":"3","start":"8000","nickName":"テンプレート2","effects":[{"id":"text-4-yes","delay":7666.666666666667},{"id":"text-7-cool","delay":740.7407407407406},{"id":"visual-5-cracker","delay":5703.7037037037035},{"id":"sound-2","delay":753.0864197530865},{"id":"sound-4","delay":4000},{"id":"sound-1","delay":5691.358024691359}]},
    {"character":"B","scene":"5","start":"15000","nickName":"ハナコ","effects":[{"id":"text-4-no","delay":0},{"id":"visual-5-heart","delay":4024.6913580246915},{"id":"sound-1","delay":4037.0370370370374},{"id":"sound-5","delay":0}]},
    {"character":"B","scene":"6","start":"0","nickName":"ロクモジさん","effects":[{"id":"text-4-kai","delay":987.6543209876543},{"id":"text-7-fun","delay":6901.234567901234},{"id":"visual-2-star","delay":3148.1481481481483},{"id":"visual-2-flower","delay":6913.58024691358},{"id":"sound-3","delay":987.6543209876543},{"id":"sound-4","delay":3148.1481481481483},{"id":"sound-4","delay":6925.925925925925}]}
  ]};
export const BACHE_ONE= {
  "bgm": 1,
  "animations": [
    {"character":"B","scene":"1","start":"0","nickName":"テンプレート1","effects":[{"id":"text-7-fun","delay":2197.5308641975307},{"id":"visual-5-cracker","delay":2209.8765432098767},{"id":"visual-2-kira","delay":8395.061728395061},{"id":"visual-5-sweat","delay":4469.135802469136},{"id":"sound-6","delay":4444.444444444444},{"id":"sound-3","delay":2209.8765432098767},{"id":"sound-2","delay":7814.814814814815}]},
    {"character":"B","scene":"3","start":"8000","nickName":"テンプレート2","effects":[{"id":"text-4-yes","delay":7666.666666666667},{"id":"text-7-cool","delay":740.7407407407406},{"id":"visual-5-cracker","delay":5703.7037037037035},{"id":"sound-2","delay":753.0864197530865},{"id":"sound-4","delay":4000},{"id":"sound-1","delay":5691.358024691359}]},
    {"character":"B","scene":"5","start":"15000","nickName":"たろう","effects":[{"id":"text-4-no","delay":0},{"id":"visual-5-heart","delay":4024.6913580246915},{"id":"sound-1","delay":4037.0370370370374},{"id":"sound-5","delay":0}]},
    {"character":"B","scene":"6","start":"0","nickName":"","テンプレート3":[{"id":"text-4-kai","delay":987.6543209876543},{"id":"text-7-fun","delay":6901.234567901234},{"id":"visual-2-star","delay":3148.1481481481483},{"id":"visual-2-flower","delay":6913.58024691358},{"id":"sound-3","delay":987.6543209876543},{"id":"sound-4","delay":3148.1481481481483},{"id":"sound-4","delay":6925.925925925925}]}
  ]};

export const SAMPLE = {"bgm":"1","animations":[{"character":"B","scene":2,"start":6482,"effects":[{"id":"","delay":0},{"id":"visual-5-heart","delay":790.1234567901234}],"nickName":"afofe"},{"character":"B","scene":3,"start":"8000","nickname":"","effects":[{"id":"text-4-yes","delay":7666.666666666667},{"id":"text-7-cool","delay":740.7407407407406},{"id":"visual-5-cracker","delay":5703.7037037037035},{"id":"sound-2","delay":753.0864197530865},{"id":"sound-4","delay":4000},{"id":"sound-1","delay":5691.358024691359}],"nickName":"テンプレート2"},{"character":"B","scene":6,"start":"0","nickname":"","effects":[{"id":"text-4-kai","delay":987.6543209876543},{"id":"text-7-fun","delay":6901.234567901234},{"id":"visual-2-star","delay":3148.1481481481483},{"id":"visual-2-flower","delay":6913.58024691358},{"id":"sound-3","delay":987.6543209876543},{"id":"sound-4","delay":3148.1481481481483},{"id":"sound-4","delay":6925.925925925925}],"nickName":"テンプレート3"},{"character":"B","scene":1,"start":"0","nickname":"","effects":[{"id":"text-7-fun","delay":2197.5308641975307},{"id":"visual-5-cracker","delay":2209.8765432098767},{"id":"visual-2-kira","delay":8395.061728395061},{"id":"visual-5-sweat","delay":4469.135802469136},{"id":"sound-6","delay":4444.444444444444},{"id":"sound-3","delay":2209.8765432098767},{"id":"sound-2","delay":7814.814814814815},{"id":"visual-2-curtain","delay":9000}],"nickName":"テンプレート4"}]}
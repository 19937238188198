const Routes = (route) => {
  switch (route) {
    case "edit":
      return "/chooseStart/";

    case "tutorial":
      return "/tutorial/";

    case "screening":
      return "/screening/";

    default:
      break;
  }
};

export default Routes;

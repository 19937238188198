import React, { Component } from 'react';
import './EffectPane.css';

class EffectPane extends Component {
  constructor(props) {
    super(props)
    this.myref = React.createRef()
    this.soundRef = React.createRef()
  }

  componentDidMount() {
    let target = this.myref.current
    target.addEventListener('load', () => {
      let anims = this.myref.current.getSVGDocument().getAnimations()
      anims.forEach((anim) => {
        anim.currentTime = 0
        anim.play()
      })
    })
  }

  changePreview(src) {
    let target = this.myref.current
    target.src = src
  }
  changeSoundPreview(src) {
    let target = this.soundRef.current
    target.volume = 0.3
    target.pause()
    target.src = src
    target.play().catch((e) => {
      console.log(e)
    })
  }

  setDragImage(event, src, width) {
    this.removeTmpDragImage()
    const img = document.createElement('img')

    img.src = src
    img.style.width = `${width}px`
    img.setAttribute('width', width)
    img.setAttribute('height', 36)

    const div = document.createElement('div')
    div.setAttribute('id', 'tmp')
    div.appendChild(img)
    div.style.position = 'absolute'
    div.style.top = `${0}px`
    div.style.left = `-${width}px`

    document.querySelector('#editor-main').appendChild(div)

    // @todo: #140 set set correct X of setDragImage
    event.dataTransfer.setDragImage(div, width / 4, 18)
  }

  removeTmpDragImage() {
    const element = document.querySelector('#tmp')
    if(element) element.remove()
  }
  render() {
    return (
      <div id='effect-pane'>
        <div id='hidden-svgs' style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}>
          {/* text effect */}
          <img src='/effect_elements/7-fun.svg' alt='effect' />
          <img src='/effect_elements/7-love.svg' alt='effect' />
          <img src='/effect_elements/7-friend.svg' alt='effect' />
          <img src='/effect_elements/7-cool.svg' alt='effect' />
          <img src='/effect_elements/7-yummy.svg' alt='effect' />
          <img src='/effect_elements/4-kai.svg' alt='effect' />
          <img src='/effect_elements/4-wow.svg' alt='effect' />
          <img src='/effect_elements/7-hmmm.svg' alt='effect' />
          <img src='/effect_elements/4-yes.svg' alt='effect' />
          <img src='/effect_elements/4-no.svg' alt='effect' />
          {/* visual effect */}
          <img src='/effect_elements/5-cracker.svg' alt='effect' />
          <img src='/effect_elements/5-heart.svg' alt='effect' />
          <img src='/effect_elements/2-star.svg' alt='effect' />
          <img src='/effect_elements/5-moya.svg' alt='effect' />
          <img src='/effect_elements/2-kira.svg' alt='effect' />
          <img src='/effect_elements/5-light.svg' alt='effect' />
          <img src='/effect_elements/5-sweat.svg' alt='effect' />
          <img src='/effect_elements/2-flower.svg' alt='effect' />
          <img src='/effect_elements/5-party.svg' alt='effect' />
          <img src='/effect_elements/2-music.svg' alt='effect' />
          {/* sound effect */}
          <img src='/effect_elements/SE1.svg' alt='effect' />
          <img src='/effect_elements/SE2.svg' alt='effect' />
          <img src='/effect_elements/SE3.svg' alt='effect' />
          <img src='/effect_elements/SE4.svg' alt='effect' />
          <img src='/effect_elements/SE5.svg' alt='effect' />
          <img src='/effect_elements/SE6.svg' alt='effect' />
        </div>
        <div>
          <div id='effect-preview'>
            <iframe id='effect-preview-elem' ref={this.myref} title='effect preview' />
          </div>
          <div className='effect-title'>
            <img src='/text_effect_icon.svg' alt='textEffect' />
            <span>Text Effect / テキストエフェクト</span>
          </div>
          {/* Text Effect 1st Row */}
          <div className='effect-row'>
            {/* Fun */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/text_effects/E-7-fun.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'text-7-fun')
                this.setDragImage(e, '/effect_elements/7-fun.svg', 154)
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <p>FUN</p>
              <p className='tooltiptext'>たのしい！</p>
            </div>

            {/* Love */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/text_effects/E-7-love.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'text-7-love')
                this.setDragImage(e, '/effect_elements/7-love.svg', 166)
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <p>LOVE</p>
              <p className='tooltiptext'>♡</p>
            </div>

            {/* Best Friend */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/text_effects/E-7-friend.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'text-7-friend')
                this.setDragImage(e, '/effect_elements/7-friend.svg', 162)
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              style={{ lineHeight: '20​​px' }}
            >
              <p className='two-line'>BEST FRIEND</p>
              <p className='tooltiptext'>なかよし</p>
            </div>

            {/* Cool */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/text_effects/E-7-cool.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'text-7-cool')
                this.setDragImage(e, '/effect_elements/7-cool.svg', 183)
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <p>COOL</p>
              <p className='tooltiptext'>かっこいい</p>
            </div>

            {/* Yummy */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/text_effects/E-7-yummy.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'text-7-yummy')
                this.setDragImage(e, '/effect_elements/7-yummy.svg', 130)
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <p className='small-elem'>YUMMY</p>
              <p className='tooltiptext'>おいしい</p>
            </div>
          </div>

          {/* TextEffect 2nd Row */}
          <div className='effect-row'>
            {/* KAI */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/text_effects/E-4-kai.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'text-4-kai')
                this.setDragImage(e, '/effect_elements/4-kai.svg', 146)
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <p>KAI!</p>
              <p className='tooltiptext'>こんにちは</p>
            </div>

            {/* WOW */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/text_effects/E-4-wow.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'text-4-wow')
                this.setDragImage(e, '/effect_elements/4-wow.svg', 98)
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <p>WOW</p>
              <p className='tooltiptext'>おどろき</p>
            </div>

            {/* Hmmm */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/text_effects/E-7-hmmm.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'text-7-hmmm')
                this.setDragImage(e, '/effect_elements/7-hmmm.svg', 243)
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <p className='small-elem'>Hmmm</p>
              <p className='tooltiptext'>うーん</p>
            </div>

            {/* Yes */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/text_effects/E-4-yes.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'text-4-yes')
                this.setDragImage(e, '/effect_elements/4-yes.svg', 162)
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <p>Yes!</p>
              <p className='tooltiptext'>やったー！</p>
            </div>

            {/* No */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/text_effects/E-4-no.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'text-4-no')
                this.setDragImage(e, '/effect_elements/4-no.svg', 162)
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <p>No!</p>
              <p className='tooltiptext'>これきらい！</p>
            </div>
          </div>
          <div className='effect-title'>
            <img src='/visual_effect_icon.svg' alt='Visual Effect' />
            <span>Visual Effect / ビジュアルエフェクト</span>
          </div>

          {/* Visual Effect 1st Row */}
          <div className='effect-row'>
            {/* Cracker */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/visual_effects/E-5-cracker.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('id', 'visual-5-cracker')
                this.setDragImage(e, '/effect_elements/5-cracker.svg', 142)
              }}
              onDragEnd={(e) => {
                this.removeTmpDragImage()
              }}
              onDrop={(e) => {
                this.removeTmpDragImage()
              }}
            >
              <img src='/effect_buttons/cracker.svg' alt='cracker visual effect' />
            </div>

            {/* Heart */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/visual_effects/E-5-heart.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('id', 'visual-5-heart')
                this.setDragImage(e, '/effect_elements/5-heart.svg', 162)
              }}
              onDragEnd={(e) => {
                this.removeTmpDragImage()
              }}
              onDrop={(e) => {
                this.removeTmpDragImage()
              }}
            >
              <img src='/effect_buttons/heart.svg' alt='heart visual effect' />
            </div>

            {/* Star */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/visual_effects/E-2-star.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('id', 'visual-2-star')
                this.setDragImage(e, '/effect_elements/2-star.svg', 203)
              }}
              onDragEnd={(e) => {
                this.removeTmpDragImage()
              }}
              onDrop={(e) => {
                this.removeTmpDragImage()
              }}
            >
              <img src='/effect_buttons/star.svg' alt='start visual effect' />
            </div>

            {/* Moya */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/visual_effects/E-5-moya.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('id', 'visual-5-moya')
                this.setDragImage(e, '/effect_elements/5-moya.svg', 195)
              }}
              onDragEnd={(e) => {
                this.removeTmpDragImage()
              }}
              onDrop={(e) => {
                this.removeTmpDragImage()
              }}
            >
              <img src='/effect_buttons/moya.svg' alt='line visual effect' />
            </div>

            {/* Kira */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/visual_effects/E-2-kira.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('id', 'visual-2-kira')
                this.setDragImage(e, '/effect_elements/2-kira.svg', 114)
              }}
              onDragEnd={(e) => {
                this.removeTmpDragImage()
              }}
              onDrop={(e) => {
                this.removeTmpDragImage()
              }}
            >
              <img src='/effect_buttons/kirakira.svg' alt='firework visual effect' />
            </div>
          </div>

          {/* Visual Effect 2nd Row */}
          <div className='effect-row'>
            {/* Light */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/visual_effects/E-5-light.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('id', 'visual-5-light')
                this.setDragImage(e, '/effect_elements/5-light.svg', 122)
              }}
              onDragEnd={(e) => {
                this.removeTmpDragImage()
              }}
              onDrop={(e) => {
                this.removeTmpDragImage()
              }}
            >
              <img src='/effect_buttons/light.svg' alt='light visual effect' />
            </div>

            {/* Sweat */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/visual_effects/E-5-sweat.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('id', 'visual-5-sweat')
                this.setDragImage(e, '/effect_elements/5-sweat.svg', 99)
              }}
              onDragEnd={(e) => {
                this.removeTmpDragImage()
              }}
              onDrop={(e) => {
                this.removeTmpDragImage()
              }}
            >
              <img src='/effect_buttons/sweat.svg' alt='water visual effect' />
            </div>

            {/* Flower */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/visual_effects/E-2-flower.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('id', 'visual-2-flower')
                this.setDragImage(e, '/effect_elements/2-flower.svg', 243)
              }}
              onDragEnd={(e) => {
                this.removeTmpDragImage()
              }}
              onDrop={(e) => {
                this.removeTmpDragImage()
              }}
            >
              <img src='/effect_buttons/flower.svg' alt='flower visual effect' />
            </div>

            {/* Party */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/visual_effects/E-5-party.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('id', 'visual-5-party')
                this.setDragImage(e, '/effect_elements/5-party.svg', 162)
              }}
              onDragEnd={(e) => {
                this.removeTmpDragImage()
              }}
              onDrop={(e) => {
                this.removeTmpDragImage()
              }}
            >
              <img src='/effect_buttons/party.svg' alt='flag visual effect' />
            </div>

            {/* Music */}
            <div
              className='effect-item'
              onClick={() => {
                this.changePreview('/visual_effects/E-2-music.svg')
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('id', 'visual-2-music')
                this.setDragImage(e, '/effect_elements/2-music.svg', 146)
              }}
              onDragEnd={(e) => {
                this.removeTmpDragImage()
              }}
              onDrop={(e) => {
                this.removeTmpDragImage()
              }}
            >
              <img src='/effect_buttons/music.svg' alt='sound visual effect' />
            </div>
          </div>
        </div>
        <div>
          {/* Sound Effect Row */}
          <div className='effect-title'>
            <img src='/sound.svg' alt='Sound Effect' />
            <span>Sound Effect / サウンドエフェクト</span>
          </div>
          <div className='effect-row sound'>
            <div
              className='effect-item sound'
              onClick={() => {
                this.changeSoundPreview('/sound_effects/1.mp3')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'sound-1')
                this.setDragImage(e, '/effect_elements/SE1.svg', 203)
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <span>1</span>
              <p className='tooltiptext'>キラキラ</p>
            </div>
            <div
              className='effect-item sound'
              onClick={() => {
                this.changeSoundPreview('/sound_effects/2.mp3')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'sound-2')
                this.setDragImage(e, '/effect_elements/SE2.svg', 162)
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <span>2</span>
              <p className='tooltiptext'>パチパチ</p>
            </div>
            <div
              className='effect-item sound'
              onClick={() => {
                this.changeSoundPreview('/sound_effects/3.mp3')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'sound-3')
                this.setDragImage(e, '/effect_elements/SE3.svg', 81)
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <span>3</span>
              <p className='tooltiptext'>ボロロン</p>
            </div>
            <div
              className='effect-item sound'
              onClick={() => {
                this.changeSoundPreview('/sound_effects/4.mp3')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'sound-4')
                this.setDragImage(e, '/effect_elements/SE4.svg', 68)
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <span>4</span>
              <p className='tooltiptext'>ピョコ</p>
            </div>
            <div
              className='effect-item sound'
              onClick={() => {
                this.changeSoundPreview('/sound_effects/5.mp3')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'sound-5')
                this.setDragImage(e, '/effect_elements/SE5.svg', 68)
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <span>5</span>
              <p className='tooltiptext'>ブブー</p>
            </div>
            <div
              className='effect-item sound'
              onClick={() => {
                this.changeSoundPreview('/sound_effects/6.mp3')
              }}
              draggable
              onDragStart={(e) => {
                e.target.dataset.ondrag = 'true'
                e.dataTransfer.setData('id', 'sound-6')
                this.setDragImage(e, '/effect_elements/SE6.svg', 115)
              }}
              onMouseDown={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'none'
              }}
              onMouseUp={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
              }}
              onDragEnd={(e) => {
                const targetTooltip = e.target.children[1]
                targetTooltip.style.display = 'block'
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
              onDragOver={(e) => {
                e.target.dataset.ondrag = 'false'
              }}
              onDrop={(e) => {
                e.target.dataset.ondrag = 'false'
                this.removeTmpDragImage()
              }}
            >
              <span>6</span>
              <p className='tooltiptext'>ビョーン</p>
            </div>
          </div>
          <audio id='sound' controls src='/media/cc0-audio/t-rex-roar.mp3' ref={this.soundRef}></audio>
        </div>
      </div>
    )
  }
}

export default EffectPane;


// @todo: #141 refactor EffectItem
// below code are lost this.myref in changePreview

// class TextEffectItem extends Component {
//   constructor(props) {
//     super(props)
//   }

//   render() {
//     return (
//       <div
//         className='effect-item'
//         onClick={() => {
//           this.props.changePreview(this.props.previewSVG)
//         }}
//         draggable
//         onDragStart={(e) => {
//           e.target.dataset.ondrag = 'true'
//           e.dataTransfer.setData('id', this.props.id)
//           this.props.setDragImage(e, this.props.elementSVG, this.props.elementWidth)
//         }}
//         onDragEnd={(e) => {
//           const targetTooltip = e.target.children[1]
//           targetTooltip.style.display = 'block'
//           e.target.dataset.ondrag = 'false'
//           this.props.removeTmpDragImage()
//         }}
//         onMouseDown={(e) => {
//           const targetTooltip = e.target.children[1]
//           targetTooltip.style.display = 'none'
//         }}
//         onMouseUp={(e) => {
//           const targetTooltip = e.target.children[1]
//           targetTooltip.style.display = 'block'
//         }}
//         onDragOver={(e) => {
//           e.target.dataset.ondrag = 'false'
//         }}
//         onDrop={(e) => {
//           e.target.dataset.ondrag = 'false'
//           this.props.removeTmpDragImage()
//         }}
//       >
//         <p>{this.props.text}</p>
//         <p className='tooltiptext'>{this.props.tooltipText}</p>
//       </div>
//     )
//   }
// }

// <TextEffectItem
//   previewSVG={'/text_effects/E-7-fun.svg'}
//   id={'text-7-fun'}
//   elementSVG={'/effect_elements/7-fun.svg'}
//   elementWidth={154}
//   text={'FUN'}
//   tooltipText={'たのしい！'}
//   changePreview={this.changePreview}
//   setDragImage={this.setDragImage}
//   removeTmpDragImage={this.removeTmpDragImage}
// />
import React, { Component } from 'react';

class NoMatchPage extends Component {
  render() {
    return (
      <div>
        <h3 style={{color: "white"}}>
          404 Not Found
        </h3>
      </div>
    );
  }
}

export default NoMatchPage;

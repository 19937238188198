const localStorageKey = "KidzaniaClient";

const Db = async (key, value) => {
  if (value === undefined) {
    const content = window.localStorage.getItem(`${localStorageKey}-${key}`);
    return content ? JSON.parse(content) : "";
  }

  window.localStorage.setItem(`${localStorageKey}-${key}`, JSON.stringify(value));
  console.log("[Db]...", `${localStorageKey}-${key}`, value);
};

export const clearLocalStorage = async () => {
  await Db(LOGIN, "");
  await Db(TUTORIAL_NO, 1);
  await Db(MOVE_PAGE, "/login");
  await Db(CLIENT_SCENE, "");
  await Db(CHOSEN_SCENES, "");
  await Db(CHOOSE_DELAY, "");
  await Db(CURRENT_MS, "");
  await Db(ANIMATION, "");
  await Db(BGM, "");
  await Db(ANIMATION_SCREENING, "");
  await Db(IS_WAITING_SORT, false);
  await Db(PEERID, "");
  await Db(SORT_DEFINITION, "");
  await Db(TEMPLATE_USERS, "");
  await Db(SORT_NO, "");
  await Db(FINAL_ANIMATION, "");
};

export const MOVE_PAGE = "MOVE_PAGE";
export const TUTORIAL_NO = "TUTORIAL_NO";
export const LOGIN = "LOGIN";
export const CLIENT_SCENE = "CLIENT_SCENE";
export const CHOSEN_SCENES = "CHOSEN_SCENES";
export const CHOOSE_DELAY = "CHOOSE_DELAY";
export const CURRENT_MS = "CURRENT_MS";
export const ANIMATION = "ANIMATION";
export const BGM = "BGM";
export const ANIMATION_SCREENING = "ANIMATION_SCREENING";
export const IS_WAITING_SORT = "IS_WAITING_SORT";
export const PEERID = "peerId";
export const SORT_DEFINITION = "SORT_DEFINITION";
export const TEMPLATE_USERS = "TEMPLATE_USERS";
export const SORT_NO = "SORT_NO";
export const FINAL_ANIMATION = "FINAL_ANIMATION";

export default Db;

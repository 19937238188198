import React, { Component } from "react";

import "./AppFooter.css";
import ChooseTimeline from "./timeline/ChooseTimeline.js";
import Timeline from "./timeline/Timeline.js";
import ScreenMovement from "./timeline/ScreenMovement.js";

class AppFooter extends Component {
  constructor(props) {
    super(props);
    this._child = React.createRef();
    this.state = {
      isChooseMode: props.isChooseMode || false,
    };
  }

  getChooseDelay() {
    if (this.state.isChooseMode) return this._child.current.getCurrentStartTimeMS();
    return 0;
  }

  playTimeline() {
    this._child.current.playTimeline();
  }

  backToZero() {
    this._child.current.backToZero();
  }

  pauseProgress() {
    this._child.current.pauseProgress();
  }

  import(effectsArray) {
    if (this._child.current.import) this._child.current.import(effectsArray);
  }

  setChooseDelay(time) {
    if (this.props.isChooseMode) this._child.current.setChooseDelay(time);
  }

  render() {
    return (
      <div className="AppFooter">
        {!this.state.isChooseMode && (
          <Timeline
            appendSVG={(id, delay) => this.props.appendSVG(id, delay)}
            removeSVG={(id) => this.props.removeSVG(id)}
            appendSound={(id, delay) => this.props.appendSound(id, delay)}
            removeSound={(id) => this.props.removeSound(id)}
            changeTime={(time_ms) => this.props.changeTime(time_ms)}
            changeTimeWithAnimControl={(time_ms) => this.props.changeTimeWithAnimControl(time_ms)}
            changeDelay={(id, delay) => this.props.changeDelay(id, delay)}
            changeSoundDelay={(id, delay_ms) => this.props.changeSoundDelay(id, delay_ms)}
            pusePreview={() => this.props.pusePreview()}
            topMargin={this.props.topMargin}
            leftMargin={this.props.leftMargin}
            ref={this._child}
          />
        )}
        {this.state.isChooseMode && (
          <ChooseTimeline
            changeTime={(time_ms) => this.props.changeTime(time_ms)}
            changeTimeWithAnimControl={(time_ms) => this.props.changeTimeWithAnimControl(time_ms)}
            changeDelay={(id, delay) => this.props.changeDelay(id, delay)}
            changeSoundDelay={(id, delay_ms) => this.props.changeSoundDelay(id, delay_ms)}
            pusePreview={() => this.props.pusePreview()}
            playPreview={() => this.props.playPreview()}
            topMargin={this.props.topMargin}
            leftMargin={this.props.leftMargin}
            ref={this._child}
          />
        )}
        <ScreenMovement
          isChooseMode={this.state.isChooseMode}
          getCurrentTrimeSec={() => {
            return this._child.current.getCurrentStartTimeMS();
          }}
          setStartTime={(ms) => {
            this.props.setStartTime(ms);
          }}
          confirmBacktoStep1={() => {
            this.props.confirmBacktoStep1();
          }}
          pauseProgress={() => {
            this.pauseProgress();
          }}
        />
      </div>
    );
  }
}

export default AppFooter;

import React, { Component } from 'react';
import './PreviewPane.css';
import { ELEMENTS_SIZE } from '../timeline/EffectElementDefinitions.js'
class PreviewPane extends Component {
  constructor(props) {
    super(props)
    this.animDivRef = React.createRef()
    this.audio1Ref = React.createRef()
    this.audio2Ref = React.createRef()
    this.audio3Ref = React.createRef()
    this.audio4Ref = React.createRef()
    this.audio5Ref = React.createRef()
    this.audio6Ref = React.createRef()
    this.audioElemRefs = [
      this.audio1Ref,
      this.audio2Ref,
      this.audio3Ref,
      this.audio4Ref,
      this.audio5Ref,
      this.audio6Ref
    ]

    // console.log('PreviewPane debug: props:', props)

    this.initScene(props.chara, props.scene)
    this.state = {
      baseStartTimeMillisec: props.start || 0,
      baseCharacterType: props.chara, // V=Vita U=Urbano B=Bache
      baseSceneNo: props.scene, // 1~6
      currentSec: '00',
      currentMillisec: '00',
      svgTelops: this.svgTelops,
      svgTextEffects1: this.svgTextEffects1,
      svgVisualEffects1: this.svgVisualEffects1,
      svgTextEffects2: this.svgTextEffects2,
      svgVisualEffects2: this.svgVisualEffects2,
      svgCharacter: this.svgCharacter,
      svgTextEffects3: this.svgTextEffects3,
      svgVisualEffects3: this.svgVisualEffects3,
      svgBackground: this.svgBackground,
      isPlaying: false,
      soundElements: [], // [{soundId, delay, targetId, isPlayed}..]
      isChooseMode: props.isChooseMode,
      maxAnmiationDurationMS: props.isChooseMode ? 30 * 1000 : 10 * 1000
    }
    this.isPlaying = false
    this.isImport = false

    // For json usage.
    this.effectElementsMap = new Map()

    if (props.json) {
      // Import after load tick.
      window.setTimeout(() => {
        this.import(props.json)
      }, 500)
    }

    this.playToggleKeyboard = this.playToggleKeyboard.bind(this)
  }

  initScene(chara, scene) {
    const fileBaseName = `/movies/${chara}${scene}-`
    const charaFileName = `${fileBaseName}6-C.svg`
    const backFilename = `${fileBaseName}9-B.svg`
    const telopFileName = `${fileBaseName}0-T.svg`

    // 内部ステート変更検知して描画するために必要。
    this.svgTelops = [
      <iframe
        id='telopSVG'
        src={telopFileName}
        onLoad={() => {
          this.changeDelay('telopSVG', 0)
          this.pause()
        }}
        title='telopSVG'
      />
    ]
    this.svgTextEffects1 = []
    this.svgVisualEffects1 = []
    this.svgTextEffects2 = []
    this.svgVisualEffects2 = []
    this.svgCharacter = [
      <iframe
        id='charaSVG'
        src={charaFileName}
        onLoad={() => {
          this.changeDelay('charaSVG', -1 * this.state.baseStartTimeMillisec)
          this.pause()
        }}
        title='charaSVG'
      />
    ]
    this.svgTextEffects3 = []
    this.svgVisualEffects3 = []
    this.svgBackground = [
      <iframe
        id='backSVG'
        src={backFilename}
        onLoad={() => {
          this.changeDelay('backSVG', -1 * this.state.baseStartTimeMillisec)
          this.pause()
        }}
        title='backSVG'
      />
    ]
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentTimeMillisec !== prevProps.currentTimeMillisec) {
      let sec = Math.floor(this.props.currentTimeMillisec / 1000)
      let secStr = '0'
      if (sec < 10) {
        secStr += sec
      } else {
        secStr = '' + sec
      }
      let ms = Math.floor((this.props.currentTimeMillisec % 1000) / 10)
      let msStr = '0'
      if (ms < 10) {
        msStr += ms
      } else {
        msStr = '' + ms
      }
      this.setState({
        currentSec: secStr,
        currentMillisec: msStr
      })

      // Sound control
      if (this.isPlaying) {
        let needUpdate = false
        let updateArray = []
        this.state.soundElements.forEach((s) => {
          if (
            s.delay > this.props.currentTimeMillisec - 50 && // @todo: should fix 50 to correct value
            s.delay < this.props.currentTimeMillisec &&
            !s.isPlayed &&
            !!this.audioElemRefs[parseInt(s.id) - 1].current
          ) {
            this.audioElemRefs[parseInt(s.id) - 1].current.currentTime = 0
            this.audioElemRefs[parseInt(s.id) - 1].current.volume = 0.3
            this.audioElemRefs[parseInt(s.id) - 1].current.play()
            s.isPlayed = true
            updateArray.push(s)
            needUpdate = true
          } else {
            updateArray.push(s)
          }
        })
        if (needUpdate) {
          this.setState({
            soundElements: updateArray
          })
        }
      }
    }
  }

  playToggleKeyboard(e) {
    if (e.code !== 'Space') return
    if (this.isPlaying) {
      this.pause()
    } else {
      this.play()
    }
  }

  componentDidMount() {
    window.addEventListener('keyup', this.playToggleKeyboard)
    // We should retry the pause when loading page until
    // find the SVG DOM.
    this.timerId = undefined
    this.retry()
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.playToggleKeyboard)
    if (this.timerId !== undefined) {
      window.clearTimeout(this.timerId)
      this.timerId = undefined
    }
  }

  // This function will retry pausing and setting the starting negative delay
  // until finishing the page load.
  retry() {
    try {
      console.log('retrying...')
      if (this.timerId !== undefined) {
        window.clearTimeout(this.timerId)
        this.timerId = undefined
      }
      this.pause()
      // console.log('retry: baseStartTimeMillisec: ', this.state.baseStartTimeMillisec)
      console.log('PreviewPane debug retry state:', this.state)
      // if (isNaN(this.state.baseStartTimeMillisec)) throw new Error('baseStartTimeMillisec is NaN')
      const svgs = ['charaSVG', 'backSVG']
      svgs.forEach((id) => {
        this.changeDelay(id, -1 * this.state.baseStartTimeMillisec)
      })
      console.log('compeleted.')
    } catch (e) {
      console.log(e)
      this.timerId = window.setTimeout(() => {
        this.retry()
      }, 500)
    }
  }

  appendSVG(id, delay) {
    this.needInitPausing = true
    // id is '<type>-<name>-<sequence>'
    let svgId = id.substr(0, id.lastIndexOf('-'))
    let type = svgId.substr(0, svgId.indexOf('-'))
    let svgName = svgId.substr(svgId.indexOf('-') + 1)
    let svgFile
    switch (type) {
      case 'visual':
        svgFile = `/visual_effects/E-${svgName}.svg`
        if (svgName.startsWith('2')) {
          this.svgVisualEffects1.push(
            <iframe
              id={id}
              src={svgFile}
              onLoad={() => {
                this.changeDelay(id, delay)
                this.pause()
              }}
              key={id}
              title={id}
            />
          )
          this.setState({
            svgVisualEffects1: this.svgVisualEffects1
          })
        } else if (svgName.startsWith('5')) {
          this.svgVisualEffects2.push(
            <iframe
              id={id}
              src={svgFile}
              onLoad={() => {
                this.changeDelay(id, delay)
                this.pause()
              }}
              key={id}
              title={id}
            />
          )
          this.setState({
            svgVisualEffects2: this.svgVisualEffects2
          })
        }
        break
      case 'text':
        svgFile = `/text_effects/E-${svgName}.svg`
        if (svgName.startsWith('4')) {
          this.svgTextEffects2.push(
            <iframe
              id={id}
              src={svgFile}
              onLoad={() => {
                this.changeDelay(id, delay)
                this.pause()
              }}
              key={id}
              title={id}
            />
          )
          this.setState({
            svgTextEffects2: this.svgTextEffects2
          })
        } else if (svgName.startsWith('7')) {
          this.svgTextEffects3.push(
            <iframe
              id={id}
              src={svgFile}
              onLoad={() => {
                this.changeDelay(id, delay)
                this.pause()
              }}
              key={id}
              title={id}
            />
          )
          this.setState({
            svgTextEffects3: this.svgTextEffects3
          })
        }
        break
      default:
        break
    }

    // console.log('debug this.effectElementsMap: ', this.effectElementsMap)
    // append effect into json
    if (!this.isImport) {
      this.effectElementsMap.set(id, delay)
      this.props.setAnimationJSON(this.export())
    }
  }

  removeSVG(id) {
    let svgId = id.substr(0, id.lastIndexOf('-'))
    let type = svgId.substr(0, svgId.indexOf('-'))
    let svgName = svgId.substr(svgId.indexOf('-') + 1)
    switch (type) {
      case 'visual':
        if (svgName.startsWith('2')) {
          this.svgVisualEffects1 = this.svgVisualEffects1.filter((obj) => obj.key !== id)
          this.setState({
            svgVisualEffects1: this.svgVisualEffects1
          })
        } else if (svgName.startsWith('5')) {
          this.svgVisualEffects2 = this.svgVisualEffects2.filter((obj) => obj.key !== id)
          this.setState({
            svgVisualEffects2: this.svgVisualEffects2
          })
        }
        break
      case 'text':
        if (svgName.startsWith('4')) {
          this.svgTextEffects2 = this.svgTextEffects2.filter((obj) => obj.key !== id)
          this.setState({
            svgTextEffects2: this.svgTextEffects2
          })
        } else if (svgName.startsWith('7')) {
          this.svgTextEffects3 = this.svgTextEffects3.filter((obj) => obj.key !== id)
          this.setState({
            svgTextEffects3: this.svgTextEffects3
          })
        }
        break
      default:
        break
    }

    // remove from json.
    this.effectElementsMap.delete(id)
    this.props.setAnimationJSON(this.export())
  }

  appendSound(id, delay = 0) {
    let soundFactor = id.split('-')
    // console.log('soundDelay:', delay)
    this.setState({
      soundElements: [
        ...this.state.soundElements,
        {
          id: soundFactor[1],
          delay,
          targetId: id,
          isPlayed: false
        }
      ]
    })

    // append sound effect into json.
    if (!this.isImport) {
      this.effectElementsMap.set(id, delay)
      this.props.setAnimationJSON(this.export())
    }
  }

  removeSound(id) {
    let arr = this.state.soundElements.filter((obj) => obj.targetId !== id)
    this.setState({
      soundElements: arr
    })

    // remove sound effect from json
    this.effectElementsMap.delete(id)
    this.props.setAnimationJSON(this.export())
  }

  changeDelay(id, delayTime) {
    // console.log('changeDelay id : ', id)
    // console.log('changeDelay delayTime: ', delayTime)

    // @todo: fix it
    // if (isNaN(delayTime)) return

    let div = this.animDivRef.current
    let targetObj = div.querySelector(`#${id}`)
    // console.log(targetObj)
    if (!targetObj) return // @todo: targetObjが見つからない場合に何が起こるのか
    let anims = targetObj.getSVGDocument().getAnimations()
    for (var anim of anims) {
      let timing = anim.effect.getTiming()
      timing.delay = delayTime
      anim.effect.updateTiming(timing)
    }
    // change delay of json
    if (id === 'charaSVG' || id === 'backSVG') return

    if (!this.isImport) {
      this.effectElementsMap.set(id, delayTime)
      // def: setAnimationJSON
      // App.js: setMyAnimationJSON
      this.props.setAnimationJSON(this.export())
    }
  }

  changeSoundDelay(id, delay_ms) {
    let sounds = []
    this.state.soundElements.forEach((s) => {
      if (s.targetId === id) {
        sounds.push({
          id: s.id,
          delay: delay_ms,
          targetId: s.targetId,
          isPlayed: false
        })
      } else {
        sounds.push(s)
      }
    })
    this.setState({
      soundElements: sounds
    })

    // change delay of json
    if (!this.isImport) {
      this.effectElementsMap.set(id, delay_ms)
      this.props.setAnimationJSON(this.export())
    }
  }

  changeTime(timeMillisec) {
    let div = this.animDivRef.current
    for (var obj of div.children) {
      let svg = obj.getSVGDocument()
      for (var anim of svg.getAnimations()) {
        anim.pause()
        anim.currentTime = timeMillisec
      }
    }
    let sec = Math.floor(timeMillisec / 1000)
    let secStr = '0'
    if (sec < 10) {
      secStr += sec
    } else {
      secStr = '' + sec
    }
    let ms = Math.floor((timeMillisec % 1000) / 10)
    let msStr = '0'
    if (ms < 10) {
      msStr += ms
    } else {
      msStr = '' + ms
    }
    this.setState({
      currentSec: secStr,
      currentMillisec: msStr
    })
    this.isPlaying = false
    this.setState({
      isPlaying: this.isPlaying
    })
  }

  pause() {
    let div = this.animDivRef.current
    if (!div) return
    for (var obj of div.children) {
      let svg = obj.getSVGDocument()
      if (!svg) {
        console.log('PrewviewPane debug pause: not SVG obj:', obj)
        continue
      }
      for (var anim of svg.getAnimations()) {
        anim.pause()
      }
    }
    this.props.pauseProgress()
    this.isPlaying = false
    this.setState({
      isPlaying: this.isPlaying
    })
    this.clearSoundsPlayHistory()
  }

  play() {
    if (this.isPlaying) {
      this.pause()
      this.isPlaying = false
      this.setState({
        isPlaying: this.isPlaying
      })
      return
    }

    if (this.props.currentTimeMillisec >= this.state.maxAnmiationDurationMS) {
      this.pause()
      this.props.backToZero()
      this.isPlaying = false
      this.setState({
        isPlaying: this.isPlaying
      })
      return
    }
    let div = this.animDivRef.current
    if (!div) return
    for (var obj of div.children) {
      let svg = obj.getSVGDocument()

      // console.log(obj.src.split('effects/E-'))
      const urls = obj.src.split('effects/E-')
      let duration = 10000
      if (urls.length > 1) {
        const fileName = urls[1]
        duration = this.getEffectElementDurationFromFileName(fileName)
        // console.log('duration: ', duration)
      }

      for (var anim of svg.getAnimations()) {
        // console.log(anim)
        let timing = anim.effect.getTiming()
        if (duration !== 10000) {
          // console.log('timing.delay', timing.delay)
          // console.log('timing.delay + duration', timing.delay + duration)
          // console.log('this.props.currentMillisec:', this.props.currentTimeMillisec)
          if (timing.delay + duration > this.props.currentTimeMillisec) {
            anim.currentTime = this.props.currentTimeMillisec
            anim.play()
          }
        } else {
          // console.log(timing.delay + duration)
          anim.currentTime = this.props.currentTimeMillisec
          anim.play()
        }
      }
    }
    this.isPlaying = true
    this.setState({
      isPlaying: this.isPlaying
    })
    this.props.startTimeline()
  }

  getEffectElementDurationFromFileName(fileName) {
    let findElemens = ELEMENTS_SIZE.filter((elems) => elems.name === fileName)

    return findElemens !== undefined && findElemens.length > 0 ? findElemens[0].duration : undefined
  }

  back() {
    this.props.backToZero()
    this.props.pauseProgress()
    this.clearSoundsPlayHistory()
  }

  clearSoundsPlayHistory() {
    let sounds = []
    this.state.soundElements.forEach((s) => {
      s.isPlayed = false
      sounds.push(s)
    })
    this.setState({
      soundElements: sounds
    })
  }

  export() {
    let json = {
      character: this.state.baseCharacterType,
      scene: this.state.baseSceneNo,
      start: parseInt(this.state.baseStartTimeMillisec),
      nickname: this.state.nickname,
      effects: Array.from(this.effectElementsMap, ([id, delay]) => {
        return {
          id: id.substr(0, id.lastIndexOf('-')),
          delay
        }
      })
    }
    return json
  }

  import(jsonObject) {
    this.isImport = true
    try {
      this.pause()
    } catch (e) {
      console.log(e)
    }
    this.initScene(jsonObject.character, jsonObject.scene)

    // remove Every effects

    console.log('PreviewPane debug: import jsonObject:', jsonObject)

    this.setState({
      baseCharacterType: jsonObject.character,
      baseSceneNo: jsonObject.scene,
      baseStartTimeMillisec: parseInt(jsonObject.start),
      currentSec: '00',
      currentMillisec: '00',
      svgTelops: this.svgTelops,
      svgTextEffects1: this.svgTextEffects1,
      svgVisualEffects1: this.svgVisualEffects1,
      svgTextEffects2: this.svgTextEffects2,
      svgVisualEffects2: this.svgVisualEffects2,
      svgCharacter: this.svgCharacter,
      svgTextEffects3: this.svgTextEffects3,
      svgVisualEffects3: this.svgVisualEffects3,
      svgBackground: this.svgBackground,
      isPlaying: false,
      soundElements: [] // [{soundId, delay, targetId, isPlayed}..]
    })

    let random = window.crypto.getRandomValues(new Uint32Array(100))
    let effects = []
    // After initialization, set the effects.
    console.log('jsonObject.effects debug :', jsonObject.effects)
    jsonObject.effects
      .filter((effect) => effect.id !== '')
      .forEach((effect, i) => {
        let id = effect.id + '-' + random[i]
        effects.push({ id, delay: effect.delay })
        if (effect.id.includes('sound')) {
          this.appendSound(id, effect.delay)
          // todo: stop speculative execution
          setTimeout(() => {
            this.changeSoundDelay(id, effect.delay)
          }, 1000)
        } else {
          this.appendSVG(id, effect.delay)
        }
      })

    // def: import
    //   AppFooter.js: import(effecstArray)
    // → Timeline.js: import(effectsArray)
    this.props.import(effects)
    this.isImport = false
  }

  render() {
    return (
      <div id={this.props.isPreviewMode ? 'preview-pane-preview' : 'preview-pane'}>
        <div>
          <div id={this.props.isPreviewMode ? 'anim-pane-preview' : 'anim-pane'} ref={this.animDivRef}>
            {this.svgBackground}
            {this.state.svgVisualEffects3.map((obj) => obj)}
            {this.svgTextEffects3.map((obj) => obj)}
            {this.svgCharacter}
            {this.state.svgVisualEffects2.map((obj) => obj)}
            {this.svgTextEffects2.map((obj) => obj)}
            {this.state.svgVisualEffects1.map((obj) => obj)}
            {this.svgTextEffects1.map((obj) => obj)}
            {this.svgTelops.map((obj) => obj)}
          </div>
        </div>
        {!this.props.isPreviewMode && (
          <div id='control-pane'>
            <img
              src='/backwards.svg'
              alt='backward button'
              onClick={() => {
                this.back()
              }}
            ></img>
            {!this.isPlaying ? (
              <img
                src='/Play.svg'
                alt='play button'
                onClick={() => {
                  this.play()
                }}
              ></img>
            ) : (
              <img
                src='/Pause.svg'
                alt='play button'
                onClick={() => {
                  this.play()
                }}
              ></img>
            )}

            <div style={{ marginLeft: '250px' }}>
              <span>
                00:{this.state.currentSec}:{this.state.currentMillisec}
              </span>
              /{this.state.isChooseMode ? <span>00:30:00</span> : <span>00:10:00</span>}
            </div>
          </div>
        )}
        <div id='audio-elems'>
          <audio id='sound1' controls src='/sound_effects/1.mp3' ref={this.audio1Ref} />
          <audio id='sound2' controls src='/sound_effects/2.mp3' ref={this.audio2Ref} />
          <audio id='sound3' controls src='/sound_effects/3.mp3' ref={this.audio3Ref} />
          <audio id='sound4' controls src='/sound_effects/4.mp3' ref={this.audio4Ref} />
          <audio id='sound5' controls src='/sound_effects/5.mp3' ref={this.audio5Ref} />
          <audio id='sound6' controls src='/sound_effects/6.mp3' ref={this.audio6Ref} />
        </div>
      </div>
    )
  }
}

export default PreviewPane;

import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import './PreviewPage.css';
import Player from '../component/player/Player.js'
import Db, { MOVE_PAGE, IS_WAITING_SORT } from "../helper/Db";

class PreviewPage extends Component {
  constructor(props) {
    super(props)

    console.log(props)

    this.playButton = React.createRef()
    this.mouseHandler = undefined
    this.currentTime = 0

    let chara = this.json?.character || this.props.chara || 'B'
    let scene = this.json?.scene || this.props.scene || '2'
    let nickname = this.json?.nickName || this.props.nickName || 'test'
    let start = this.json?.start || this.props.startTime || 15 * 1000
    this.state = {
      chara,
      scene,
      nickname,
      start,
      gotoEdit: false,
      waitingSort: this.props.waitingSort !== undefined ? this.props.waitingSort : false,
      gotoChooseBGM: false,
      isPlaying: false,
      gotoScreening: false
    }

    this.preview = React.createRef()
    this._page = React.createRef();

    this.mouseHandler = undefined
  }

  componentDidMount() {
    if (this.preview.current) {
      if (this.props.json) {
        this.preview.current.import(this.props.json)
      } else {
        this.preview.current.import(
          JSON.parse(
            `{"character":"U","scene":"2","nickname":"","effects":[{"id":"visual-5-cracker", "delay": 0},{"id":"visual-5-cracker","delay":6629.62962962963},{"id":"visual-5-light","delay":2975.3086419753085},{"id":"text-7-fun","delay":5370.37037037037},{"id":"sound-2","delay":4716.049382716049}]}`
          )
        )
      }
      setTimeout(() => {
        if (!this.state.waitingSort)
          this.preview.current.pause()
      }, 300)
      window.addEventListener("mousemove", (e) => {
        this.mouseCursor(e);
      })
      this.mouseHandler = window.setInterval(() => {
        if (this.props.skyway && this._page.current) {
          const rect = this._page.current.getBoundingClientRect()
          const x = (this.pointX - rect.x) / 1440
          const y = (this.pointY - rect.y) / 900
          const pointX = x > 1 ? 1 : x
          const pointY = y > 1 ? 1 : y
          this.props.skyway.sendData({
            messageType: 'mousePosition',
            nickName: this.props.nickName,
            pointX,
            pointY,
            step: 3,
            currentTime: this.currentTime,
          })
        }
      }, 200)
      if (this.props.skyway) {
        this.props.skyway.addReceiveHandler(
          {
            receive: (e) => {
              let type = e.data.messageType
              console.log(e)
              switch (type) {
                case 'movePage':
                  if (this.preview && this.preview.current) {
                    this.preview.current.pause() // 遷移の前に、確実にclearInterval
                  }
                  let page = e.data.target
                  if (page === 'sort') {
                    this.setState({
                      waitingSort: true
                    })
                    Db(IS_WAITING_SORT, true);
                  } else if (page === 'chooseBGM') {
                    this.setState({
                      waitingSort: false,
                      gotoChooseBGM: true
                    })
                    Db(MOVE_PAGE, "/chooseBGM/");
                    Db(IS_WAITING_SORT, false);
                  } else if (page === "screening") {
                    this.setState({
                      waitingSort: false,
                      gotoScreening: true
                    })
                    Db(MOVE_PAGE, "/screening/");
                  }
                  break
                default:
                  break
              }
            },
            erro: (e) => {}
          },
          'preview'
        )
      }
      Db(IS_WAITING_SORT).then((res) => {
        if (res) {
          this.setState({
            waitingSort: res
          });
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.props.skyway) this.props.skyway.removeReceiveHandler('preview')
    if (this.mouseHandler) window.clearInterval(this.mouseHandler)
  }

  mouseCursor(e) {
    this.pointX = e.clientX;
    this.pointY = e.clientY;
  }

  backToZero() {
    console.log('backToZero')
  }
  pauseProgress() {
    console.log('pauseProgress')
  }
  startTimeline() {
    console.log('startTimeline')
  }

  backToEdit() {
    console.log('goto edit.')
    this.preview.current?.pause()
    this.setState({
      gotoEdit: true
    })
    Db(MOVE_PAGE, "/edit/");
  }

  startPlay() {
    this.setState({
      isPlaying: true
    })
    this.playButton.current.style.visibility = 'hidden'
    this.preview.current?.play()
  }

  render() {
    if (this.state.waitingSort) {
      return (
        <div className='EditorPageContainer'>
          <div className='EditorPage'>
            <img src='/Loading.svg' alt='loading' style={{  width: '1440px'  }}></img>
          </div>
        </div>
      )
    }
    if (this.state.gotoChooseBGM) {
      return <Redirect to='/chooseBGM/' />
    }
    if (this.state.gotoEdit) {
      return <Redirect to='/edit/' />
    }
    if (this.state.gotoScreening) {
      return <Redirect to='/screening/' />
    }
    return (
      <div className='PreviewPage-container' ref={this._page}>
        <div className='PreviewPage'>
          <Player
            startTimeline={this.startTimeline}
            backToZero={this.backToZero}
            pauseProgress={this.pauseProgress}
            import={() => {}}
            chara={this.state.chara}
            scene={this.state.scene}
            start={this.state.start}
            updateCurrentTime={(time) => {
              this.currentTime = time
            }}
            endPlaying={() => {
              this.setState({
                isPlaying: false
              })
            }}
            ref={this.preview}
          />
          <div id='backToEdit'>
            <img
              src='/preview/back_step2.svg'
              onClick={() => {
                this.backToEdit()
              }}
              alt='back to step2'
            />
          </div>
          {!this.state.isPlaying && (
            <div
              id='play-button'
              onClick={() => {
                this.startPlay()
              }}
              ref={this.playButton}
            >
              <img src='/preview/play_button.svg' alt='play'></img>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default PreviewPage;

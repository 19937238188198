export const ELEMENTS_SIZE = [
    {
        name:"2-flower.svg",
        width: 243,
        duration: 243*1000/81,
      },
      {
        name:"2-kira.svg",
        width: 114,
        duration: 114*1000/81,
      },
      {
        name:"2-music.svg",
        width: 146,
        duration: 146*1000/81,
      },
      {
        name:"2-star.svg",
        width: 203,
        duration: 203*1000/81,
      },
      {
        name:"4-kai.svg",
        width: 146,
        duration: 146*1000/81,
      },
      {
        name:"4-no.svg",
        width: 162,
        duration: 162*1000/81,
      },
      {
        name:"4-wow.svg",
        width: 98,
        duration: 98*1000/81,
      },
      {
        name:"4-yes.svg",
        width: 162,
        duration: 162*1000/81,
      },
      {
        name:"5-cracker.svg",
        width: 142,
        duration: 142*1000/81,
      },
      {
        name:"5-heart.svg",
        width: 162,
        duration: 162*1000/81,
      },
      {
        name:"5-light.svg",
        width: 122,
        duration: 122*1000/81,
      },
      {
        name:"5-moya.svg",
        width: 195,
        duration: 195*1000/81,
      },
      {
        name:"5-party.svg",
        width: 162,
        duration: 162*1000/81,
      },
      {
        name:"5-sweat.svg",
        width: 99,
        duration: 99*1000/81,
      },
      {
        name:"7-cool.svg",
        width: 183,
        duration: 183*1000/81,
      },
      {
        name:"7-friend.svg",
        width: 162,
        duration: 162*1000/81,
      },
      {
        name:"7-fun.svg",
        width: 154,
        duration: 154*1000/81,
      },
      {
        name:"7-hmmm.svg",
        width: 243,
        duration: 243*1000/81,
      },
      {
        name:"7-love.svg",
        width: 166,
        duration: 166*1000/81,
      },
      {
        name:"7-yummy.svg",
        width: 130,
        duration: 130*1000/81,
      },
      {
        name:"SE1.svg",
        width: 203,
        duration: 203*1000/81,
      },
      {
        name:"SE2.svg",
        width: 162,
        duration: 162*1000/81,
      },
      {
        name:"SE3.svg",
        width: 81,
        duration: 81*1000/81
      },
      {
        name:"SE4.svg",
        width: 68,
        duration: 68*1000/81
      },
      {
        name:"SE5.svg",
        width: 68,
        duration: 68*1000/81
      },
      {
        name:"SE6.svg",
        width: 115,
        duration: 115*1000/81,
      }      
]
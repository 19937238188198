import React, { Component } from "react";
import "./App.css";
import EditorPage from "./pages/EditorPage.js";
import LoginPage from "./pages/Login.js";
import TutorialPage from "./pages/Tutorial.js";
import NoMatchPage from "./pages/NoMatch.js";
import ChoosePage from "./pages/ChoosePage.js";
import ChooseScenePage from "./pages/ChooseScene.js";
import PreviewPage from "./pages/PreviewPage.js";
import ChooseBGM from "./pages/ChooseBGM.js";
import ScreeningPage from "./pages/ScreeningPage.js";
import Redirect from "./pages/Redirect.js";
import Db, 
{
  ANIMATION,
  ANIMATION_SCREENING,
  MOVE_PAGE,
  CHOSEN_SCENES,
  SORT_DEFINITION,
  clearLocalStorage, 
  TEMPLATE_USERS,
  TUTORIAL_NO,
  SORT_NO,
  FINAL_ANIMATION,
  BGM,
} from "./helper/Db";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WatchPage from "./pages/WatchPage";
import { UAParser } from 'ua-parser-js';

class App extends Component {
  constructor(props) {
    super(props);
    const uaParser = new UAParser(window.navigator.userAgent)
    const browser = uaParser.getBrowser().name
    const browserVersion = uaParser.getBrowser().version.split(".")
    const os = uaParser.getOS()

    this.state = {
      skyway: undefined,
      chara: undefined,
      nickName: undefined,
      chooseCharacter: undefined,
      chooseScene: undefined,
      chooseStartTime: 0,
      myAnimationJSON: undefined,
      screeningAnimJSON: undefined,
      moveTo: undefined,
      skipBeforeunloadEvent: false,
      env: `${browser} ${browserVersion[0]}/${os.name} ${os.version}`,
    };
    this.beforeUnloaded = this.beforeUnloaded.bind(this)
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.beforeUnloaded);

    Db(ANIMATION).then((res) => {
      if (res) {
        this.setState({ myAnimationJSON: res });
      }
    });

    Db(ANIMATION_SCREENING).then((res) => {
      if (res) {
        this.setState({ screeningAnimJSON: res });
      }
    });
  }

  componentWillUnmount() {
    if (this.state.skyway) this.state.skyway.removeReceiveHandler("screening");
  }

  beforeUnloaded(e) {
    if (this.state.skipBeforeunloadEvent) return
    e.preventDefault();
    e.returnValue = "";
  }

  removeUnloadHandler() {
    this.setState({
      skipBeforeunloadEvent: true,
    })
  }

  setSkywayObject(obj) {
    this.setState({
      skyway: obj,
    });

    if (obj) {
      obj.addReceiveHandler(
        {
          lastPageMove: undefined,
          lastTutorialNo: undefined,
          sorting: [], //[nickName1, ...]
          bgmId: "",
          finalAnimation: undefined,
          templates: new Map(), // [{nickname(テンプレート1-4), sceneId}]
          receive: (e, thisArg) => {
            let type = e.data.messageType;
            switch (type) {
              case "closeSlot":
                clearLocalStorage();
                window.removeEventListener("beforeunload", this.beforeUnloaded);
                this.setState({ moveTo: process.env.REACT_APP_FINISH_REDIRECT_URL });
                break;
              // Below case save the latest message from zv for restoring.
              case "movePage":
                thisArg.lastPageMove = e.data.target;
                break;
              case "chosenScene":
                if (e.data.nickName.includes("テンプレート") || e.data.isTemplateUser) {
                  thisArg.templates.set(e.data.nickName, e.data.sceneId);
                  Db(TEMPLATE_USERS, [...thisArg.templates]);
                }
                break;
              case "changeTutorial":
                thisArg.lastTutorialNo = e.data.tutorialNo;
                break;
              case "decideBGM":
                console.log(e.data)
                thisArg.bgmId = e.data.json.bgm;
                Db(BGM, e.data.json.bgm);
                break;
              case "screeningMovies":
                thisArg.finalAnimation = e.data.animations;
                Db(FINAL_ANIMATION, e.data.animations);
                break;
              case "decidedOrdering":
                thisArg.sorting = e.data.orderNo;
                Db(SORT_NO, e.data.orderNo);
                break;
              case "sortDefinition":
                thisArg.sortDefinition = e.data.def;
                Db(SORT_DEFINITION, e.data.def);
                break;

              default:
                break;
            }
          },
          collectReconnectionData: async (thisArg) => {
            // ZV への復旧情報は、現在の変数にない場合（つまりクライアントも落ちていた場合）は
            // ローカルストレージから復旧させる
            let lastPageMove = thisArg.lastPageMove;
            if (!lastPageMove) lastPageMove = await Db(MOVE_PAGE);
            let userAnimation = this.state.myAnimationJSON;
            if (!userAnimation) userAnimation = await Db(ANIMATION);
            let chooseScene = await Db(CHOSEN_SCENES);
            let templatesMap = thisArg.templates;
            if (!templatesMap || templatesMap.size === 0) templatesMap = new Map(await Db(TEMPLATE_USERS));
            let def = thisArg.sortDefinition;
            if (!def) def = await Db(SORT_DEFINITION);
            let sorting = thisArg.sorting;
            if (!sorting || sorting.length === 0) sorting = await Db(SORT_NO);
            let tutorialNo = thisArg.tutorialNo;
            if (!tutorialNo) tutorialNo = parseInt(await Db(TUTORIAL_NO)) + 1;
            let finalAnimation = thisArg.finalAnimation;
            if (!finalAnimation) finalAnimation = await Db(FINAL_ANIMATION);
            let bgmId = thisArg.bgmId;
            if (!bgmId) bgmId = await Db(BGM);

            // If ZV crash, we notify the necessary information via login request.
            // In this return value will be used ZV recovery process.
            return {
              lastPageMove,
              finalAnimation,
              userAnimation,
              chooseScene,
              bgmId,
              sorting,
              def,
              templates: Array.from(templatesMap, t => { return {nickName: t[0], sceneId: t[1]}}),
              tutorialNo,
              env: this.state.env,
            }
          },
          erro: (e) => {},
        },
        "common"
      );
    }
  }

  setNickName(name) {
    console.log(name);
    this.setState({
      nickName: name,
    });
  }

  setChooseCharacter(chara) {
    this.setState({
      chooseCharacter: chara,
    });
  }

  setChooseScene(scene) {
    console.log(scene);
    this.setState({
      chooseScene: scene,
    });
  }

  setChooseStartTime(timeMs) {
    console.log(timeMs);
    let json = this.state.myAnimationJSON;
    if (json !== undefined) {
      json.start = timeMs;
    }
    this.setState({
      chooseStartTime: timeMs,
      myAnimationJSON: json,
    });

    if (this.state.skyway) {
      this.state.skyway.sendData({
        messageType: "animation",
        nickName: this.state.nickName,
        json,
      });
    }
  }

  setMyAnimationJSON(json) {
    if (json === undefined) {
      json = this.state.myAnimationJSON;
      json.start = 0;
      json.effects = [];
    }
    this.setState({
      myAnimationJSON: json,
    });

    if (this.state.skyway) {
      this.state.skyway.sendData({
        messageType: "animation",
        nickName: this.state.nickName,
        json,
      });
    }
    Db(ANIMATION, json);
  }

  setScreeningAnimJSON(json) {
    console.log(json);
    this.setState({
      screeningAnimJSON: json,
    });
    Db(ANIMATION_SCREENING, json);
  }

  // Condition is:
  //  has Skyway object that initializing when registering nickname.
  //  env is dev.
  acceptRendering() {
    return this.state.skyway || process.env.REACT_APP_ENV === "dev";
  }

  render() {
    return (
      <Router>
        {this.state.moveTo && <Redirect loc={this.state.moveTo} />}

        <Switch>
          <Route path="/login">
            <LoginPage
              setSkyway={(obj) => {
                this.setSkywayObject(obj);
              }}
              setNickName={(name) => {
                this.setNickName(name);
              }}
              setChooseCharacter={(chara) => {
                this.setChooseCharacter(chara);
              }}
              setScreeningData={(json) => {
                this.setScreeningAnimJSON(json);
              }}
              setChooseScene={(scene) => {
                this.setChooseScene(scene);
              }}
            />
          </Route>
          <Route path="/tutorial">
            {this.acceptRendering() ? (
              <TutorialPage skyway={this.state.skyway} />
            ) : (
              <Redirect loc={process.env.REACT_APP_REDIRECT_URL}></Redirect>
            )}
          </Route>
          <Route path="/chooseScene">
            {this.acceptRendering() ? (
              <ChooseScenePage
                skyway={this.state.skyway}
                nickName={this.state.nickName}
                chara={this.state.chooseCharacter}
                scene={this.state.chooseScene}
                setChooseScene={(scene) => {
                  this.setChooseScene(scene);
                }}
                setNickName={(name) => {
                  this.setNickName(name);
                }}
                setChooseCharacter={(chara) => {
                  this.setChooseCharacter(chara);
                }}
                setScreeningData={(json) => {
                  this.setScreeningAnimJSON(json);
                }}
              />
            ) : (
              <Redirect loc={process.env.REACT_APP_REDIRECT_URL}></Redirect>
            )}
          </Route>
          <Route path="/chooseStart">
            {this.acceptRendering() ? (
              <ChoosePage
                skyway={this.state.skyway}
                nickName={this.state.nickName}
                chara={this.state.chooseCharacter?.substr(0, 1).toUpperCase()}
                scene={this.state.chooseScene}
                setChooseScene={(scene) => {
                  this.setChooseScene(scene);
                }}
                setNickName={(name) => {
                  this.setNickName(name);
                }}
                setChooseCharacter={(chara) => {
                  this.setChooseCharacter(chara);
                }}
                setStartTime={(ms) => this.setChooseStartTime(ms)}
                setAnimationJSON={(json) => this.setMyAnimationJSON(json)}
              />
            ) : (
              <Redirect loc={process.env.REACT_APP_REDIRECT_URL}></Redirect>
            )}
          </Route>
          <Route path="/edit">
            {this.acceptRendering() ? (
              <EditorPage
                skyway={this.state.skyway}
                nickName={this.state.nickName}
                chara={this.state.chooseCharacter?.substr(0, 1).toUpperCase()}
                scene={this.state.chooseScene}
                startTime={this.state.chooseStartTime}
                json={this.state.myAnimationJSON}
                setChooseScene={(scene) => {
                  this.setChooseScene(scene);
                }}
                setNickName={(name) => {
                  this.setNickName(name);
                }}
                setChooseCharacter={(chara) => {
                  this.setChooseCharacter(chara);
                }}
                setStartTime={(ms) => this.setChooseStartTime(ms)}
                setAnimationJSON={(json) => this.setMyAnimationJSON(json)}
              />
            ) : (
              <Redirect loc={process.env.REACT_APP_REDIRECT_URL}></Redirect>
            )}
          </Route>
          <Route path="/preview">
            {this.acceptRendering() ? (
              <PreviewPage
                skyway={this.state.skyway}
                nickName={this.state.nickName}
                chara={this.state.chooseCharacter?.substr(0, 1).toUpperCase()}
                scene={this.state.chooseScene}
                startTime={this.state.chooseStartTime}
                json={this.state.myAnimationJSON}
              />
            ) : (
              <Redirect loc={process.env.REACT_APP_REDIRECT_URL}></Redirect>
            )}
          </Route>
          <Route path="/chooseBGM">
            {this.acceptRendering() ? (
              <ChooseBGM
                skyway={this.state.skyway}
                nickName={this.state.nickName}
                chara={this.state.chooseCharacter?.substr(0, 1).toUpperCase()}
                setScreeningData={(json) => {
                  this.setScreeningAnimJSON(json);
                }}
              />
            ) : (
              <Redirect loc={process.env.REACT_APP_REDIRECT_URL}></Redirect>
            )}
          </Route>
          <Route path="/screening">
            {this.acceptRendering() ? (
              <ScreeningPage
                skyway={this.state.skyway}
                nickName={this.state.nickName}
                chara={this.state.chooseCharacter?.substr(0, 1).toUpperCase()}
                json={this.state.screeningAnimJSON}
                setScreeningData={(json) => {
                  this.setScreeningAnimJSON(json);
                }}
              />
            ) : (
              <Redirect loc={process.env.REACT_APP_REDIRECT_URL}></Redirect>
            )}
          </Route>
          <Route path="/watch">
            <WatchPage
              removeUnloadHandler={() => { this.removeUnloadHandler() }}
            />
          </Route>
          <Route path="/">
            <Redirect loc="/requirement.html"></Redirect>
          </Route>
          <Route path="*">
            <NoMatchPage />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;

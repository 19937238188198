import React, { Component } from 'react';
import './Timeline.css';
import { ELEMENTS_SIZE } from './EffectElementDefinitions.js'

const BASE_LEFT = 250;
const PX_PER_SEC = 81;
const TICK_TIME_MS = 30;
const MAX_ANIM_DURATION_SEC = 10;
const MAX_ANIM_DURATION_NSEC = MAX_ANIM_DURATION_SEC * 1000;
const BASE_LEFT_FROM_BODY = 43;
const MIN_LEFT_FROM_BODY = 41
const MAX_LEFT_FROM_BODY = BASE_LEFT_FROM_BODY + (PX_PER_SEC * MAX_ANIM_DURATION_SEC);
class Timeline extends Component {
  constructor(props) {
    super(props)
    this.soundViewElement = []
    this.visualEffectViewElements = []
    this.textEffectViewElements = []
    this.movingInfo = {
      element: null,
      startX: 0,
      startLeft: 0
    }
    this.progressElem = React.createRef()
    this.textEffectElem = React.createRef()
    this.visualEffectElem = React.createRef()
    this.soundEffectElem = React.createRef()
    this.trashElem = React.createRef()

    // 内部ステート変更検知して描画するために必要。
    this.state = {
      soundShowData: this.soundViewElement,
      visualEffectShowData: this.visualEffectViewElements,
      textShowData: this.textEffectViewElements,
      isChooseMode: this.props.isChooseMode
    }

    // エフェクトのD&D制御
    this.mouseMove = this.mouseMove.bind(this)
    this.mouseUp = this.mouseUp.bind(this)

    // タイムラインの制御
    this.isTimelineMove = false
    this.timelineMouseDown = this.timelineMouseDown.bind(this)
    this.timelineMouseUp = this.timelineMouseUp.bind(this)
    this.timelineMouseMove = this.timelineMouseMove.bind(this)

    // タイムラインバーの制御
    this.currentTime = 0
    this.isPlaying = false
    this.timerHandler = undefined

    //ゴミ箱参照用
    this.trashStartX = 0
    this.trashEndX = 0
  }

  componentWillUnmount() {
    if (this.timerHandler) window.clearInterval(this.timerHandler)
  }

  playTimeline() {
    // 自動再生の開始
    this.isPlaying = true
    const startCurrentTime = this.currentTime
    const startBaseTime = new Date()
    this.timerHandler = window.setInterval(() => {
      let diff = new Date() - startBaseTime
      this.currentTime = startCurrentTime + diff
      if (this.currentTime > MAX_ANIM_DURATION_NSEC) {
        window.clearInterval(this.timerHandler)
        this.timerHandler = undefined
        this.props.pusePreview()
        this.props.changeTime(MAX_ANIM_DURATION_NSEC)
        return
      }
      if (!this.isPlaying) {
        console.log('is playing is false')
      }
      if (!this.progressElem.current) {
        window.clearInterval(this.timerHandler)
        return
      }
      let div = this.progressElem.current
      div.style.left = BASE_LEFT + (this.currentTime / 1000) * PX_PER_SEC + 'px'

      this.props.changeTime(this.currentTime)
    }, TICK_TIME_MS)
  }

  pauseProgress() {
    if (this.timerHandler) {
      window.clearInterval(this.timerHandler)
      this.timerHandler = undefined
      this.props.changeTimeWithAnimControl(this.currentTime)
    }
  }

  backToZero() {
    this.currentTime = 0
    if (!this.progressElem.current) return
    let div = this.progressElem.current
    div.style.left = BASE_LEFT + 'px'
    this.props.changeTime(0)
    this.props.changeTimeWithAnimControl(0)
  }

  // 以下タイムラインバーのマウス制御
  timelineMouseDown(e) {
    this.isTimelineMove = true
    window.addEventListener('mouseup', this.timelineMouseUp)
    window.addEventListener('mousemove', this.timelineMouseMove)
    if (this.timerHandler) {
      window.clearInterval(this.timerHandler)
      this.timerHandler = undefined
      this.isPlaying = false
    }
    e.preventDefault()
  }

  timelineMouseUp(e) {
    this.isTimelineMove = false
    window.removeEventListener('mouseup', this.timelineMouseUp)
    window.removeEventListener('mousemove', this.timelineMouseMove)
    let diff = ((e.clientX - BASE_LEFT - this.props.leftMargin) / PX_PER_SEC) * 1000
    if (diff < 0) diff = 0
    if (diff > MAX_ANIM_DURATION_NSEC) diff = MAX_ANIM_DURATION_NSEC
    this.props.changeTime(diff)
  }

  timelineMouseMove(e) {
    let left = e.clientX - this.props.leftMargin
    if (left < BASE_LEFT) {
      left = BASE_LEFT
    }
    if (left > 1060) {
      left = 1060
    }
    let div = this.progressElem.current
    div.style.left = left + 'px'
    let diff = ((left - BASE_LEFT) / PX_PER_SEC) * 1000
    this.props.changeTimeWithAnimControl(diff)
    this.currentTime = diff
  }

  // 以下、エフェクト要素のマウスイベント
  mouseDown(e) {
    this.movingInfo.element = e.target
    this.movingInfo.startX = e.clientX
    let left = getComputedStyle(e.target).left
    this.movingInfo.left = !left ? 0 : left.substr(0, left.indexOf('px'))
    window.addEventListener('mouseup', this.mouseUp)
    window.addEventListener('mousemove', this.mouseMove)
  }

  mouseUp(e) {
    const id = this.movingInfo.element.id
    if (e.clientX - this.props.leftMargin > this.trashStartX && e.clientX - this.props.leftMargin < this.trashEndX) {
      this.removeEffect(id)
      if (this.trashElem.current.classList.contains('hover')) {
        this.trashElem.current.classList.toggle('hover')
      }
    } else {
      let left = this.movingInfo.left - (this.movingInfo.startX - e.clientX)
      let width = window.getComputedStyle(this.movingInfo.element).width
      width = parseInt(width.substr(0, width.indexOf('px')))
      if (width + left >= MAX_LEFT_FROM_BODY) {
        left = this.movingInfo.left
      }

      // dupilication check.
      try {
        left = this.calculateElementLeftPosition(id, width, left)
      } catch (e) {
        console.log(e)
        // @todo: failした場合に明後日の方向に行く問題
        left = this.movingInfo.left
        console.log('unavailable6: Left:', left)
      }
      this.movingInfo.element.style.left = left + 'px'
      this.movingInfo.element.style.opacity = 1.0
      let delaytime = ((left - MIN_LEFT_FROM_BODY) / PX_PER_SEC) * 1000
      if (id.includes('sound-')) {
        this.props.changeSoundDelay(id.substr(0, id.lastIndexOf('-')), delaytime)
      } else {
        this.props.changeDelay(id.substr(0, id.lastIndexOf('-')), delaytime)
      }
    }
    this.movingInfo.element.style.cursor = 'pointer'
    this.movingInfo.element = null
    this.movingInfo.startX = 0
    this.movingInfo.left = 0
    window.removeEventListener('mouseup', this.mouseUp)
    window.removeEventListener('mousemove', this.mouseMove)
  }

  mouseMove(e) {
    if (!this.movingInfo.element) return
    let left = this.movingInfo.left - (this.movingInfo.startX - e.clientX)
    let width = window.getComputedStyle(this.movingInfo.element).width
    width = parseInt(width.substr(0, width.indexOf('px')))

    // If the mouse is far from timeline, should finish the dragevent.
    if (left >= MAX_LEFT_FROM_BODY + 40) {
      left = MAX_LEFT_FROM_BODY - width + 40
      console.log('max is 50px')
    }

    // If the effect element going to trash, visual effect will be removable effect.(i.g., opacity)
    if (width + left >= MAX_LEFT_FROM_BODY) {
      this.movingInfo.element.style.opacity = 0.5
    } else {
      this.movingInfo.element.style.opacity = 1.0
    }

    if (this.trashStartX === 0) {
      const style = getComputedStyle(this.trashElem.current)
      this.trashStartX = parseInt(style.left.substr(0, style.left.indexOf('px')))
      this.trashEndX = this.trashStartX + parseInt(style.width.substr(0, style.width.indexOf('px')))
    }
    if (e.clientX - this.props.leftMargin > this.trashStartX && e.clientX - this.props.leftMargin < this.trashEndX) {
      console.log('trash')
      this.movingInfo.element.style.cursor = 'zoom-out'
      if (!this.trashElem.current.classList.contains('hover')) {
        this.trashElem.current.classList.toggle('hover')
      }
    } else {
      this.movingInfo.element.style.cursor = 'pointer'
      if (this.trashElem.current.classList.contains('hover')) {
        this.trashElem.current.classList.toggle('hover')
      }
    }

    // minimum left
    if (left < MIN_LEFT_FROM_BODY) left = MIN_LEFT_FROM_BODY

    this.movingInfo.element.style.left = left + 'px'
  }

  // Check timeline duplication and overlay.
  // This calculation is high cost. So DONT CALL FREQUENTLY.
  calculateElementLeftPosition(targetEffectElemId, targetEffectElemWidth, targetEffectElemLeft) {
    const type = targetEffectElemId.substr(0, targetEffectElemId.indexOf('-'))
    let parent =
      type === 'visual'
        ? this.visualEffectElem.current
        : type === 'text'
        ? this.textEffectElem.current
        : this.soundEffectElem.current
    const effectElements = Array.from(parent.children).filter((obj) => obj.id !== targetEffectElemId)

    // 1. left position adjustment
    if (targetEffectElemLeft < MIN_LEFT_FROM_BODY) targetEffectElemLeft = MIN_LEFT_FROM_BODY

    // 2. right position adjustment
    if (targetEffectElemLeft + targetEffectElemWidth > MAX_LEFT_FROM_BODY)
      targetEffectElemLeft = MAX_LEFT_FROM_BODY - targetEffectElemWidth

    // 3. calculte available spaces(filter space.width >= targetElemWidth)
    let defaultSpaces = [[MIN_LEFT_FROM_BODY, MAX_LEFT_FROM_BODY]] // space= [left, right]

    const availableSpaces = effectElements
      .reduce((spaces, element) => this.splitSpaces(spaces, element), defaultSpaces)
      .filter((space) => space[1] - space[0] >= targetEffectElemWidth)

    console.log('availableSpaces:', availableSpaces)

    // 収まるとこがなければ返す
    if (availableSpaces.length === 0) throw new Error('Adjustment process is fail.')

    let targetEffectElemRight = targetEffectElemLeft + targetEffectElemWidth

    // どこかにきれいに収まるか
    let spaceIndex = -1
    let secondTargetSpaceIndex = -1
    availableSpaces.forEach((space, i) => {
      if (this.hasInterSection(space, targetEffectElemLeft, targetEffectElemRight)) secondTargetSpaceIndex = i
      if (this.isElementInSpace(space, targetEffectElemLeft, targetEffectElemRight)) {
        spaceIndex = i
        return
      }
    })
    if (spaceIndex !== -1) {
      console.log('available1: Left: ', targetEffectElemLeft)
      return targetEffectElemLeft // 収まったら決定
    }

    // 収まらなかった場合、交差のあったところにいれる
    // @todo: 2つ以上あった場合、どちらにいれるかの処理を考える
    if (secondTargetSpaceIndex !== -1) {
      const space = availableSpaces[secondTargetSpaceIndex]
      if (space[0] <= targetEffectElemLeft && targetEffectElemLeft <= space[1]) {
        console.log('available2: Left: ', space[1] - targetEffectElemWidth)
        return space[1] - targetEffectElemWidth // 右端を合わせる
      } else {
        console.log('available3: Left: ', targetEffectElemLeft)
        return space[0] // 左端を合わせる
      }
    }

    // 交差もなかった場合、座標が一番近いところに入れる
    let minDistance = 2000
    let minDistanceIndex = -1
    availableSpaces.forEach((space, i) => {
      const distance = this.getDistance(space, targetEffectElemLeft, targetEffectElemRight)
      if (distance < minDistance) minDistance = i
    })
    if (minDistanceIndex === 2000 || minDistanceIndex === -1) {
      throw new Error('Adjustment process is fail.')
    }
    const space = availableSpaces[minDistanceIndex]

    if (targetEffectElemRight < space[0]) {
      // effectが領域より左側なら、領域の左端に合わせる
      console.log('available4: Left: ', space[0])
      return space[0]
    } else {
      console.log('available5: Left: ', space[1] - targetEffectElemWidth)
      return space[1] - targetEffectElemWidth // effectが領域より右なら、領域の右端に合わせる
    }
  }

  splitSpaces(spaces, element) {
    const style = getComputedStyle(element)
    if (!style.left || !style.width) return spaces
    const elementLeft = parseInt(style.left.substr(0, style.left.indexOf('px')))
    const elementWidth = parseInt(style.width.substr(0, style.width.indexOf('px')))
    const elementRight = elementLeft + elementWidth

    let index = -1
    spaces.forEach((space, i) => {
      if (this.isElementInSpace(space, elementLeft, elementRight)) {
        index = i
        return
      }
    })

    if (index === -1) return [] // @todo: どこにも入らなかった場合の条件は必要?

    const newSpaces = this.splitSpace(spaces[index], elementLeft, elementRight)
    spaces.splice(index, 1) // remove old splitted space
    spaces.splice(index, 0, newSpaces[0], newSpaces[1]) // add new spaces
    return spaces
  }

  hasInterSection(space, elementLeft, elementRight) {
    // isIntersect?
    return (
      (space[0] <= elementLeft && elementLeft <= space[1]) || (space[0] <= elementRight && elementRight <= space[1])
    )
  }

  isElementInSpace(space, elementLeft, elementRight) {
    return space[0] <= elementLeft && elementRight <= space[1]
  }

  splitSpace(space, elementLeft, elementRight) {
    return [
      [space[0], elementLeft],
      [elementRight, space[1]]
    ]
  }

  // @todo:
  getDistance(space, elementLeft, elementRight) {
    return
  }

  // Remove the effect element and notify to preview pane to remove it.
  removeEffect(id) {
    const targetId = id.substr(0, id.lastIndexOf('-'))
    if (id.includes('visual')) {
      this.visualEffectViewElements = this.visualEffectViewElements.filter((obj) => {
        return obj.key !== targetId
      })
      this.setState({
        visualEffectShowData: [...this.visualEffectViewElements]
      })
      this.props.removeSVG(targetId)
    } else if (id.includes('text')) {
      this.textEffectViewElements = this.textEffectViewElements.filter((obj) => {
        return obj.key !== targetId
      })
      this.setState({
        textShowData: [...this.textEffectViewElements]
      })
      this.props.removeSVG(targetId)
    } else if (id.includes('sound')) {
      this.soundViewElement = this.soundViewElement.filter((obj) => obj.key !== targetId)
      this.setState({
        soundShowData: [...this.soundViewElement]
      })
      this.props.removeSound(targetId)
    }
  }

  // プログレスバー移動によるタイムライン時間の変更
  changeTime(time_ms) {
    this.props.changeTimeWithAnimControl(time_ms)
  }

  import(effectArray) {
    this.visualEffectViewElements = []
    this.textEffectViewElements = []
    this.soundViewElement = []
    effectArray.forEach((effect, i) => {
      // インポート時に誤差が発生するため計算結果から 2px 除去する
      let left = Math.ceil((parseFloat(effect.delay) / 1000) * PX_PER_SEC + BASE_LEFT_FROM_BODY) - 2

      // id is [type]-[no]-[name]-[date]
      // e.g., visual-5-cracker-2039484
      // Note: sound hasn't name. (sound-5-3948392)
      let items = effect.id.split('-')
      let id = `${items[1]}-${items[2]}`
      let targetId = ''
      let resource = `/effect_elements/${id}.svg`
      switch (items[0]) {
        case 'visual':
          targetId += `visual-${id}-${items[3]}`
          this.visualEffectViewElements.push(
            <div
              className={`visual-indicator ${items[1]}-${items[2]}`}
              id={targetId + '-elem'}
              onMouseDown={(e) => {
                this.mouseDown(e)
              }}
              key={targetId}
              style={{ left }}
            >
              <img src={resource} style={{ pointerEvents: 'none' }} alt='visual effect'></img>
            </div>
          )
          this.setState({
            visualEffectShowData: this.visualEffectViewElements
          })
          break
        case 'text':
          targetId += `text-${id}-${items[3]}`
          this.textEffectViewElements.push(
            <div
              className={`visual-indicator ${items[1]}-${items[2]}`}
              id={targetId + '-elem'}
              onMouseDown={(e) => {
                this.mouseDown(e)
              }}
              key={targetId}
              style={{ left }}
            >
              <img src={resource} style={{ pointerEvents: 'none' }} alt='visual effect'></img>
            </div>
          )
          this.setState({
            textEffectShowData: this.textEffectViewElements
          })
          break
        case 'sound':
          targetId += `sound-${id}`
          // targetId += items[2]
          this.soundViewElement.push(
            <div
              className='sound-indicator'
              id={targetId + '-elem'}
              onMouseDown={(e) => {
                this.mouseDown(e)
              }}
              key={targetId}
              style={{ left }}
            >
              <img
                src={`/effect_elements/SE${items[1]}.svg`}
                style={{ pointerEvents: 'none' }}
                alt='sound effect'
              ></img>
            </div>
          )
          this.setState({
            soundShowData: this.soundViewElement
          })
          break
        default:
          break
      }
    })
  }

  getEffectElementWidthFromFileName(fileName) {
    let findElemens = ELEMENTS_SIZE.filter((elems) => elems.name === fileName)

    return findElemens !== undefined && findElemens.length > 0 ? findElemens[0].width : undefined
  }

  getEffectElementDurationFromFileName(fileName) {
    let findElemens = ELEMENTS_SIZE.filter((elems) => elems.name === fileName)

    return findElemens !== undefined && findElemens.length > 0 ? findElemens[0].duration : undefined
  }

  appendSoundEffectElement(id, x) {
    const targetId = `sound-${id}-${Date.now()}`
    const fileName = `SE${id}.svg`
    const resource = `/effect_elements/${fileName}`
    let left = x
    let width = this.getEffectElementWidthFromFileName(fileName)
    try {
      left = this.calculateElementLeftPosition(targetId, width, x)
    } catch (e) {
      // If the animation doesn't fit in the timeline,
      // ignore the adding it.
      return
    }

    this.soundViewElement.push(
      <div
        className='sound-indicator'
        id={targetId + '-elem'}
        onMouseDown={(e) => {
          this.mouseDown(e)
        }}
        key={targetId}
        style={{ left }}
      >
        <img src={resource} style={{ pointerEvents: 'none' }} alt='sound effect'></img>
      </div>
    )
    this.setState({
      soundShowData: this.soundViewElement
    })
    const delay = ((left - MIN_LEFT_FROM_BODY) / PX_PER_SEC) * 1000
    this.props.appendSound(targetId, delay)
  }

  appendVisualEffectElement(id, x) {
    const targetId = `visual-${id}-${Date.now()}`
    const fileName = `${id}.svg`
    const resource = `/effect_elements/${fileName}`
    let left = x
    console.log('left:', left)
    let width = this.getEffectElementWidthFromFileName(fileName)
    console.log('width:', width)
    try {
      left = this.calculateElementLeftPosition(targetId, width, x)
    } catch (e) {
      // If the animation doesn't fit in the timeline,
      // ignore the adding it.
      return
    }
    this.visualEffectViewElements.push(
      <div
        className={`visual-indicator ${id}`}
        id={targetId + '-elem'}
        onMouseDown={(e) => {
          this.mouseDown(e)
        }}
        key={targetId}
        style={{ left }}
      >
        <img src={resource} style={{ pointerEvents: 'none' }} alt='visual effect'></img>
      </div>
    )
    this.setState({
      visualEffectShowData: this.visualEffectViewElements
    })

    const delay = ((left - MIN_LEFT_FROM_BODY) / PX_PER_SEC) * 1000
    this.props.appendSVG(targetId, delay)
  }

  appendTextEffectElement(id, x) {
    const targetId = `text-${id}-${Date.now()}`
    const fileName = `${id}.svg`
    const resource = `/effect_elements/${fileName}`
    let left = x
    let width = this.getEffectElementWidthFromFileName(fileName)
    try {
      left = this.calculateElementLeftPosition(targetId, width, x)
    } catch (e) {
      // If the animation doesn't fit in the timeline,
      // ignore the adding it.
      return
    }
    this.textEffectViewElements.push(
      <div
        className={`text-indicator ${id}`}
        id={targetId + '-elem'}
        onMouseDown={(e) => {
          this.mouseDown(e)
        }}
        key={targetId}
        style={{ left }}
      >
        <img src={resource} style={{ pointerEvents: 'none' }} alt='text effect'></img>
      </div>
    )
    this.setState({
      textEffectShowData: this.textEffectViewElements
    })

    const delay = ((left - MIN_LEFT_FROM_BODY) / PX_PER_SEC) * 1000
    this.props.appendSVG(targetId, delay)
  }

  render() {
    return (
      <div id='timeline-main'>
        <div
          id='timeline'
          onDragOver={(e) => {
            e.preventDefault()
          }}
          onDrop={(e) => {
            let id = e.dataTransfer.getData('id')
            if (id.includes('visual-')) {
              this.appendVisualEffectElement(id.substr(7), e.clientX - 260 - this.props.leftMargin)
            } else if (id.includes('text-')) {
              this.appendTextEffectElement(id.substr(5), e.clientX - 260 - this.props.leftMargin)
            } else if (id.includes('sound-')) {
              this.appendSoundEffectElement(id.substr(6), e.clientX - 260 - this.props.leftMargin)
            }
          }}
        >
          <div id='tick' className='seek-row'>
            <div className='header'></div>
            <div className='body'>
              <div>00s</div>
              <div>01s</div>
              <div>02s</div>
              <div>03s</div>
              <div>04s</div>
              <div>05s</div>
              <div>06s</div>
              <div>07s</div>
              <div>08s</div>
              <div>09s</div>
              <div>10s</div>
            </div>
          </div>
          <div id='video-seek' className='seek-row'>
            <div className='header'>
              <img src='/camera2.svg' alt='camera svg'></img>
              <p style={{ marginTop: '27px' }}>Video</p>
            </div>
            <div className='body'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div id='text-seek' className='seek-row'>
            <div className='header'>
              <img src='/text_effect_icon_gray2.svg' alt='text effect icon'></img>
              <p>Text Effect</p>
              <p className='tooltiptext'>テキストエフェクト</p>
            </div>
            <div className='body' ref={this.textEffectElem}>
              {this.textEffectViewElements.map((obj) => obj)}
            </div>
          </div>
          <div id='visual-seek' className='seek-row'>
            <div className='header'>
              <img src='/visual_effect_icon_gray2.svg' alt='visual effect icon svg'></img>
              <p>Visual Effect</p>
              <p className='tooltiptext'>ビジュアルエフェクト</p>
            </div>
            <div className='body' ref={this.visualEffectElem}>
              {this.visualEffectViewElements.map((obj) => obj)}
            </div>
            <div className='trash-space'></div>
          </div>
          <div id='sound-seek' className='seek-row'>
            <div className='header'>
              <img src='/sound_icon2.svg' alt='sound icon svg'></img>
              <p>Sound Effect</p>
              <p className='tooltiptext'>サウンドエフェクト</p>
            </div>
            <div className='body' ref={this.soundEffectElem}>
              {this.soundViewElement.map((obj) => obj)}
            </div>
          </div>
        </div>
        <div id='progressbar' ref={this.progressElem} onMouseDown={(e) => this.timelineMouseDown(e)}>
          <div>
            <p>
              <svg xmlns='http://www.w3.org/2000/svg' width='13' height='202' fill='none' viewBox='0 0 13 202'>
                <path fill='#DBC44E' d='M0 0v3.3L4.73 11h.77v191h2V11h.77L13 3.3V0H0z' />
              </svg>
            </p>
          </div>
        </div>
        <div id='trash' ref={this.trashElem}>
          <img src='/trash.svg' alt='trash'></img>
        </div>
      </div>
    )
  }
}

export default Timeline;

import React, { Component } from "react";
import "./ScreeningPlayer.css";
import {FIXED_RENDERED_ELEMENTS} from './ScreeningPlayerDefinitions'

const TICK_MS = 50;
const CREDIT_END_TIME_MS = 8 * 1000;
const OPENING_END_TIME_MS = 13 * 1000;
const NO_1_END_TIME_MS = 23 * 1000;
const NO_2_END_TIME_MS = 33 * 1000;
const NO_3_END_TIME_MS = 43 * 1000;
const NO_4_END_TIME_MS = 53 * 1000;
const ENDING_END_TIME_MS = 75 * 1000;
const TOTAL_MOVIE_END_TIME_MS = 75 * 1000;
// 上映用コンポーネント
// <ScreeningPlayer date="2021.04.02" volume=1.0 loop=true paused=true importLoad={callback} finishAnimation={callback}>
class ScreeningPlayer extends Component {
  constructor(props) {
    super(props)
    this.animDivRef = React.createRef()
    this.audio1Ref = React.createRef()
    this.audio2Ref = React.createRef()
    this.audio3Ref = React.createRef()
    this.audio4Ref = React.createRef()
    this.audio5Ref = React.createRef()
    this.audio6Ref = React.createRef()
    this.urbanoBGM1Ref = React.createRef()
    this.urbanoBGM2Ref = React.createRef()
    this.urbanoBGM3Ref = React.createRef()
    this.vitaBGM1Ref = React.createRef()
    this.vitaBGM2Ref = React.createRef()
    this.vitaBGM3Ref = React.createRef()
    this.bacheBGM1Ref = React.createRef()
    this.bacheBGM2Ref = React.createRef()
    this.bacheBGM3Ref = React.createRef()
    this.audioElemRefs = [
      this.audio1Ref,
      this.audio2Ref,
      this.audio3Ref,
      this.audio4Ref,
      this.audio5Ref,
      this.audio6Ref,
      this.urbanoBGM1Ref,
      this.urbanoBGM2Ref,
      this.urbanoBGM3Ref,
      this.vitaBGM1Ref,
      this.vitaBGM2Ref,
      this.vitaBGM3Ref,
      this.bacheBGM1Ref,
      this.bacheBGM2Ref,
      this.bacheBGM3Ref
    ]

    // commmon
    this.character = ''
    this.nickNames = []

    // Loading Controls
    // this lazy load components map manage the need loading component work finish or not.
    // key is element id, value is load is finish or not (true is finish)
    this.lazyLoadComponentsMap = new Map()
    // Dynamic loaded array
    this.dynamiLoadedElementsId = []
    // Modern browser, loading process is based on network process.
    // There are many thread, as result of this thread,
    // Fire the load event. So this flag will ignore the multi load event.
    this.hasFiredLoadEvent = false
    // Some browser doesn't fire the audio / video load event.
    // So prevent doesn't firing load event, this player will use the timer;
    this.loadTimer = undefined

    // Individual Movies
    this.svgBackground = [[], [], [], []]
    this.svgTextEffects3 = [[], [], [], []]
    this.svgCharacter = [[], [], [], []]
    this.svgVisualEffects2 = [[], [], [], []]
    this.svgTextEffects2 = [[], [], [], []]
    this.svgVisualEffects1 = [[], [], [], []]
    this.svgTelops = [[], [], [], []]
    this.svgCurtain = []

    // 再生制御
    this.endTimes = [
      CREDIT_END_TIME_MS,
      OPENING_END_TIME_MS,
      NO_1_END_TIME_MS,
      NO_2_END_TIME_MS,
      NO_3_END_TIME_MS,
      NO_4_END_TIME_MS,
      ENDING_END_TIME_MS,
      TOTAL_MOVIE_END_TIME_MS
    ]
    // 制御の処理短縮のための次ターゲットの時間計測
    this.currentTarget = 0
    this.currentTargetEndDuration = 0
    this.currentDivRef = undefined

    this.creditDivRef = React.createRef()
    this.openingDivRef = React.createRef()
    this.no1DivRef = React.createRef()
    this.no2DivRef = React.createRef()
    this.no3DivRef = React.createRef()
    this.no4DivRef = React.createRef()
    this.endingRef = React.createRef()
    this.animationDivRefs = [
      this.creditDivRef,
      this.openingDivRef,
      this.no1DivRef,
      this.no2DivRef,
      this.no3DivRef,
      this.no4DivRef,
      this.endingRef,
      undefined
    ]

    this.volume = props.volume || 1.0
    this.soundElements = []

    // クレジット用日付
    let label = this.props.date
    if (!label || label === '') {
      const now = new Date()
      label = `${now.getFullYear()}.${('0' + (now.getMonth() + 1)).slice(-2)}.${('0' + (now.getDate())).slice(-2)}`
    }

    this.state = {
      maxAnmiationDurationMS: 76 * 1000,
      soundElements: this.soundElements,
      character: this.character,
      participants: 0,
      bgm: 1,
      importStatus: 'none',  //[none/importing/finish] none: first state, importing: after import func, finish: end of import
      creditDate: label,
    };
    this.isPlaying = false;
    this.startPlayingTimeMS = 0;
    this.animationObserverHandler = undefined;
    this.animationObserver = this.animationObserver.bind(this);
    this.loadingRef = React.createRef()
  }

  componentWillUnmount() {
    if (this.animationObserverHandler) window.clearInterval(this.animationObserverHandler)
    if (this.loadTimer) window.clearTimeout(this.loadTimer)
  }

  // userNo start with zero.
  // sceneId start with 1-
  appendSVG(userNo, sceneId, id, delay) {
    if (id === '') return
    // id is '<type>-<no>-<name>-<else(optional)>'
    // like visual-5-cracker
    // common-common
    let splittedId = id.split('-')
    let type = splittedId[0]
    let svgName = `${splittedId[1]}-${splittedId[2]}`
    delay = parseInt(delay) // + initialTime;
    let svgFile
    switch (type) {
      case 'visual':
        svgFile = `/visual_effects/E-${svgName}.svg`
        if (svgName.startsWith('2')) {
          if (id === 'visual-2-curtain') {
            this.svgCurtain.push(
              <iframe
                id={`${id}${userNo}${delay}`}
                key={`${id}${userNo}${delay}`}
                src={svgFile}
                onLoad={() => {
                  this.changeDelay(userNo, id, delay, delay)
                }}
                title={`${id}${userNo}${delay}`}
              />
            )
          } else {
            this.svgVisualEffects1[userNo].push(
              <iframe
                id={`${id}${userNo}${delay}`}
                key={`${id}${userNo}${delay}`}
                src={svgFile}
                onLoad={() => {
                  this.changeDelay(userNo, id, delay, delay)
                }}
                title={`${id}${userNo}${delay}`}
              />
            )
          }
        } else if (svgName.startsWith('5')) {
          this.svgVisualEffects2[userNo].push(
            <iframe
              id={`${id}${userNo}${delay}`}
              key={`${id}${userNo}${delay}`}
              src={svgFile}
              onLoad={() => {
                this.changeDelay(userNo, id, delay, delay)
              }}
              title={`${id}${userNo}${delay}`}
            />
          )
        }
        break
      case 'text':
        svgFile = `/text_effects/E-${svgName}.svg`
        if (svgName.startsWith('4')) {
          this.svgTextEffects2[userNo].push(
            <iframe
              id={`${id}${userNo}${delay}`}
              key={`${id}${userNo}${delay}`}
              src={svgFile}
              onLoad={() => {
                this.changeDelay(userNo, id, delay, delay)
              }}
              title={`${id}${userNo}${delay}`}
            />
          )
        } else if (svgName.startsWith('7')) {
          this.svgTextEffects3[userNo].push(
            <iframe
              id={`${id}${userNo}${delay}`}
              key={`${id}${userNo}${delay}`}
              src={svgFile}
              onLoad={() => {
                this.changeDelay(userNo, id, delay, delay)
              }}
              title={`${id}${userNo}${delay}`}
            />
          )
        }
        break
      case 'common':
        let charaFirstLetter = this.character.substr(0, 1).toUpperCase()
        let telopFile = `/movies/${charaFirstLetter}${sceneId}-0-T.svg`
        let charaFile = `/movies/${charaFirstLetter}${sceneId}-6-C.svg`
        let backFile = `/movies/${charaFirstLetter}${sceneId}-9-B.svg`
        this.svgBackground[userNo].push(
          <iframe
            id={`${id}${userNo}${delay}Back`}
            key={`${id}${userNo}${delay}Back`}
            src={backFile}
            onLoad={() => {
              this.changeDelay(userNo, id, delay, -1 * delay, 'Back')
            }}
            title={`${id}${userNo}${delay}`}
          />
        )
        this.svgCharacter[userNo].push(
          <iframe
            id={`${id}${userNo}${delay}Chara`}
            key={`${id}${userNo}${delay}Chara`}
            src={charaFile}
            onLoad={() => {
              this.changeDelay(userNo, id, delay, -1 * delay, 'Chara')
            }}
            title={`${id}${userNo}${delay}`}
          />
        )
        this.svgTelops[userNo].push(
          <iframe
            id={`${id}${userNo}${delay}Telop`}
            key={`${id}${userNo}${delay}Telop`}
            src={telopFile}
            onLoad={() => {
              this.changeDelay(userNo, id, delay, 0, 'Telop')
            }}
            title={`${id}${userNo}${delay}`}
          />
        )
        break
      default:
        break
    }
  }

  // userNo start with zero.
  changeDelay(userNo, id, delayTime, actualDelay, suffix = '') {
    let div = this.animationDivRefs[userNo + 2].current
    let delay = parseInt(actualDelay)
    let delayName = parseInt(delayTime)
    let targetObj = div.querySelector(`#${id}${userNo}${delayName}${suffix}`)
    if (!targetObj) {
      console.log("can't find the delayed effect.", `#${id}${userNo}${delayName}${suffix}`)
      return
    }
    let anims = targetObj.getSVGDocument().getAnimations()
    for (var anim of anims) {
      let timing = anim.effect.getTiming()
      timing.delay = delay
      try {
        anim.effect.updateTiming(timing)
      } catch (e) {
        console.log(e, delay, id)
      }
      anim.currentTime = 0
      anim.pause()
    }

    // Load finished.
    this.loadFinish(`${id}${userNo}${delayName}${suffix}`)
  }

  // userNo start with zero.
  appendSound(userNo, id, delay = 0) {
    // sound id countain the 'sound' prefex and no.
    // like below:
    //     sound-2
    let initialTime = OPENING_END_TIME_MS + userNo * 10 * 1000
    let soundFactor = id.split('-')
    this.soundElements.push({
      id: soundFactor[1],
      delay: delay + initialTime,
      targetId: `${userNo}-${id}`,
      isPlayed: false
    })
    this.setState({
      soundElements: this.soundElements
    })
  }

  pauseWithZero() {
    this.pause(true, 0)
  }

  pause(setTime = false, time = 0) {
    this.pauseDuration = Date.now() - this.startPlayingTimeMS
    if (!this.currentDivRef) {
      if (this.animationObserverHandler) {
        window.clearInterval(this.animationObserverHandler)
      }
      return
    }
    
    for (var obj of this.currentDivRef.current.children) {
      if (!obj.getSVGDocument) continue
      let svg = obj.getSVGDocument()
      if (!svg) continue
      for (var anim of svg.getAnimations()) {
        console.log(anim)
        if (setTime) anim.currentTime = time
        anim.pause()
      }
    }
    this.isPlaying = false
    this.setState({
      isPlaying: this.isPlaying
    })

    // audio
    this.pausedAudios = []
    this.audioElemRefs.forEach((ref) => {
      if (setTime) ref.current.currentTime = 0
      if (!ref.current.paused) {
        this.pausedAudios.push({
          elemRef: ref,
          time: ref.current.currentTime,
        })
      }
      ref.current.pause()
    })

    if (setTime && time === 0) {
      this.clearSoundsPlayHistory()
    }
    if (this.animationObserverHandler) {
      window.clearInterval(this.animationObserverHandler)
      this.animationObserverHandler = undefined
    }
  }

  resume() {
    this.startPlayingTimeMS = Date.now() - this.pauseDuration
    this.animationObserverHandler = window.setInterval(this.animationObserver, TICK_MS)
    
    for (var obj of this.currentDivRef.current.children) {
      if (!obj.getSVGDocument) continue
      let svg = obj.getSVGDocument()
      if (!svg) continue
      for (var anim of svg.getAnimations()) {
        anim.play()
      }
    }
    this.isPlaying = true
    this.setState({
      isPlaying: this.isPlaying,
    })
    // audio
    this.pausedAudios.forEach(audio => {
      if (audio.time > 0) audio.elemRef.current.play()
    })
  }

  play() {
    if (this.isPlaying) {
      this.pause()
      this.isPlaying = false
      this.setState({
        isPlaying: this.isPlaying
      })
      return
    }

    this.animationDivRefs.forEach((ref) => {
      if (ref) {
        ref.current.style.visibility = 'visible'
      }
    })

    this.currentTarget = 0
    this.currentTargetEndDuration = this.endTimes[this.currentTarget]
    this.currentDivRef = this.animationDivRefs[this.currentTarget]
    console.log('animtion start', this.currentTargetEndDuration, this.currentDivRef.current?.id)
    this.playSpecifiedTime(this.currentDivRef.current)

    this.isPlaying = true
    this.startPlayingTimeMS = Date.now()
    this.animationObserverHandler = window.setInterval(this.animationObserver, TICK_MS)
    this.setState({
      isPlaying: this.isPlaying
    })
  }

  animationObserver() {
    let duration = Date.now() - this.startPlayingTimeMS
    if (duration > this.currentTargetEndDuration) {
      console.log(
        'index',
        this.currentTarget,
        'duration',
        this.currentTargetEndDuration,
        'curDuration',
        duration,
        this.currentDivRef
      )
      // transition for next movie
      // current animation div will be invisible.
      this.currentTargetEndDuration = this.endTimes[++this.currentTarget]
      if (
        this.currentDivRef &&
        this.currentDivRef.current &&
        !!this.currentTargetEndDuration &&
        this.currentTarget < 7
      ) {
        this.currentDivRef.current.style.visibility = 'hidden'
      }
      this.currentDivRef = this.animationDivRefs[this.currentTarget]
      if (this.currentDivRef) {
        this.playSpecifiedTime(this.currentDivRef.current)
      }
      if (!this.currentTargetEndDuration) {
        console.log('animation ended.')
        this.isPlaying = false
        this.setState({
          isPlaying: this.isPlaying
        })
        if (this.animationObserverHandler) {
          window.clearInterval(this.animationObserverHandler)
        }

        // イベントリスナー発火
        console.log(this.props.finishAnimation)
        if (this.props.finishAnimation) {
          this.props.finishAnimation()
        }

        if (this.props.loop) {
          setTimeout(() => {
            this.pauseWithZero()
            this.play()
          })
        }
      }
    }
    // Sound control
    let needUpdate = false
    let updateArray = []
    this.soundElements.forEach((s) => {
      if (s.delay < duration && !s.isPlayed && !!this.audioElemRefs[parseInt(s.id) - 1].current) {
        this.audioElemRefs[parseInt(s.id) - 1].current.currentTime = 0
        this.audioElemRefs[parseInt(s.id) - 1].current.volume = this.volume
        this.audioElemRefs[parseInt(s.id) - 1].current.play()
        s.isPlayed = true
        updateArray.push(s)
        needUpdate = true
      } else {
        updateArray.push(s)
      }
    })
    if (needUpdate) {
      this.setState({
        soundElements: updateArray
      })
    }
  }

  playSpecifiedTime(divElem, time=0) {
    if (!divElem) return
    for (var obj of divElem.children) {
      if (!obj.getSVGDocument) continue
      let svg = obj.getSVGDocument()
      if (!svg) continue
      for (var anim of svg.getAnimations()) {
        anim.currentTime = time
        anim.play()
      }
    }
  }

  clearSoundsPlayHistory() {
    let sounds = []
    this.state.soundElements.forEach((s) => {
      s.isPlayed = false
      sounds.push(s)
    })
    this.setState({
      soundElements: sounds
    })
  }

  addUnfinishedElem(id) {
    // カーテン動画だけ管理対象外
    if (id.includes('curtain')) return
    if (this.loadTimer === undefined) {
      this.loadTimer = window.setTimeout(() => {
        // ロード完了後にインポートも終了として映像を再生始める
        this.setState({
          importStatus: this.state.importStatus === 'none' ? 'none' : 'finish',
        })
        this.loadEvent()
      }, 10 * 1000)
    }
    this.lazyLoadComponentsMap.set(id, false)
    this.dynamiLoadedElementsId.push(id)
  }

  // 各要素のロード完了時に実行される
  // <audio> 要素の場合は、再生前に発火する
  loadFinish(id) {
    // カーテン動画だけ管理対象外
    if (id.includes('curtain')) return
    if (this.state.isPlaying && (id.includes('sound') || id.includes('Bgm'))) return
    this.lazyLoadComponentsMap.set(id, true)
    if (this.determineAllElementLoaded()) {
      // 全てロードしたらロード完了を発火
      setTimeout(() => {
        if (!this.hasFiredLoadEvent) {
          if (this.loadTimer !== undefined) {
            window.clearTimeout(this.loadTimer)
            this.loadTimer = undefined
          }
          this.hasFiredLoadEvent = true
          this.loadEvent()
        }
      }, 100)
    }
  }

  loadEvent() {
    if (this.props.importLoad) {
      this.props.importLoad()
    }
    if (this.props.paused) {
      // 自動再生させない場合は何もしない
      return;
    }
    this.pauseWithZero()
    this.play()
  }

  removeAllElementLoadRecord() {
    // 固定のクレジット・オープニング・エンディング・オーディオ以外のロード記録を削除
    // (動的に変わるものだけ削除)
    this.lazyLoadComponentsMap.forEach((val, id) => {
      if (!FIXED_RENDERED_ELEMENTS.includes(id)) {
        this.lazyLoadComponentsMap.delete(id)
      }
    })
    this.dynamiLoadedElementsId = []
    this.hasFiredLoadEvent = false
    if (this.loadTimer !== undefined) {
      window.clearTimeout(this.loadTimer)
      this.loadTimer = undefined
    }
  }

  determineAllElementLoaded() {
    // 固定のクレジット・オープニング・エンディング・オーディオがロードされているかチェック
    let unfinished = FIXED_RENDERED_ELEMENTS.filter((id) => !this.lazyLoadComponentsMap.get(id))
    if (unfinished.length > 0) return false

    // 動的に追加されたエフェクトのロードが完了しているかチェック
    unfinished = this.dynamiLoadedElementsId.filter((id) => !this.lazyLoadComponentsMap.get(id))
    if (unfinished.length > 0) return false

    // ロード完了後にインポートも終了として映像を再生始める
    this.setState({
      importStatus: this.state.importStatus === 'none' ? 'none' : 'finish',
    })

    return true
  }

  import(jsonObject, dateLabel) {
    console.log(jsonObject)
    this.removeAllElementLoadRecord()
    this.pause()
    this.svgBackground = [[], [], [], []]
    this.svgTextEffects3 = [[], [], [], []]
    this.svgCharacter = [[], [], [], []]
    this.svgVisualEffects2 = [[], [], [], []]
    this.svgTextEffects2 = [[], [], [], []]
    this.svgVisualEffects1 = [[], [], [], []]
    this.svgTelops = [[], [], [], []]
    this.svgCurtain = []

    this.character = jsonObject.animations[0].character
    this.character = this.character === 'U' ? 'urbano' : this.character === 'V' ? 'vita' : 'bache'
    this.soundElements = []
    this.setState({
      isPlaying: false,
      soundElements: this.soundElements,
      character: this.character,
      bgm: jsonObject.bgm,
      importStatus: 'importing',
      creditDate: (dateLabel && dateLabel.length > 0) ? dateLabel : this.state.creditDate
    });
    // ステートの反映をするため 1tick ずらす
    setTimeout(() => {
      this.importAfterProcess(jsonObject)
    }, 0)
  }

  importAfterProcess(jsonObject) {
    let bgmNo = this.character === "urbano" ? 0 : this.character === "vita" ? 1 : 2;
    bgmNo = 7 + bgmNo * 3 + (parseInt(jsonObject.bgm) - 1);
    // hack : bgm should be in zero start position.
    this.appendSound(-1.3, 'sound-' + bgmNo, 0)

    // Append curtain animatio on last.
    if (jsonObject.animations[3]) {
      if (!jsonObject.animations[3].effects.some((effect) => effect.id === 'visual-2-curtain')) {
        jsonObject.animations[3].effects.push({
          id: 'visual-2-curtain',
          delay: 9000
        })
      }
    }

    this.nickNames = []
    jsonObject.animations.forEach((anim, i) => {
      if (anim.nickName.includes('テンプレート')) {
        anim.nickName = ''
      }
      this.nickNames.push(anim.nickName)
    })
    // Sort. empty nick name should be last
    this.nickNames = this.nickNames.sort((a, b) => {
      if (a === b) { return 0; }
      else if (a === '') { return 1; }
      else if (b === '') { return -1;}
      else { return a < b ? -1 : 1; }
    })
    this.setState({
      participants: this.nickNames.filter((obj) => obj !== '').length
    })

    // Before loading element, check the count of needing load managed component.
    jsonObject.animations.forEach((anim, i) => {
      // common
      this.addUnfinishedElem(`common-common${i}${anim.start}Telop`)
      this.addUnfinishedElem(`common-common${i}${anim.start}Back`)
      this.addUnfinishedElem(`common-common${i}${anim.start}Chara`)
      // effect
      anim.effects.forEach((effect) => {
        const delay = parseInt(effect.delay)
        if (effect.id !== '' && !effect.id.includes('sound')) {
          this.addUnfinishedElem(`${effect.id}${i}${delay}`)
        } else {
          // Each sound doesn't need manage here.
          // audio managed as common element.
        }
      })
    })

    jsonObject.animations.forEach((anim, i) => {
      // append back / front / telop
      let startDelay = anim.start
      this.appendSVG(i, anim.scene, 'common-common', startDelay)

      anim.effects.forEach((effect) => {
        if (effect.id.includes('sound')) {
          this.appendSound(i, effect.id, effect.delay)
        } else {
          this.appendSVG(i, anim.scene, effect.id, effect.delay)
        }
      })
    })
  }

  // Credit name and Date
  changeCreditSceneNickName() {
    let root = this.creditDivRef.current.children[0].getSVGDocument()
    for (let no = 1; no <= 4; no++) {
      let nameText = root.getElementById(`credit_title_${this.character}-name` + no)
      nameText.textContent = this.nickNames[no - 1]
    }
    let dateLabel = root.getElementById(`credit_title_${this.character}-timestamp`)
    dateLabel.textContent = this.state.creditDate

    let anims = this.creditDivRef.current.children[0].getSVGDocument().getAnimations()
    anims.forEach((anim) => {
      anim.currentTime = 0
      anim.pause()
    })
    this.loadFinish('credit')
  }
  // End roll name and Date
  changeEndrollNickname() {
    let root = this.endingRef.current.children[0].getSVGDocument()
    if (this.state.participants === 1) {
      let nameText = root.getElementById(`ed_${this.character}_1-name1`)
      nameText.textContent = this.nickNames.filter((obj) => obj !== '')[0]
    } else {
      for (let no = 1; no <= this.state.participants; no++) {
        let nameText = root.getElementById(`ed_${this.character}_4-name${no}`)
        nameText.textContent = this.nickNames[no - 1]
      }
    }
    const participantsPrefix = this.state.participants === 1 ? 1 : 4
    let dateLabel = root.getElementById(`ed_${this.character}_${participantsPrefix}-timestamp`)
    dateLabel.textContent = this.state.creditDate

    let anims = this.endingRef.current.children[0].getSVGDocument().getAnimations()
    anims.forEach((anim) => {
      anim.currentTime = 0
      anim.pause()
    })
    this.loadFinish('ending')
  }

  // Changing the SVG Delay
  changeSVGDelay(json) {
    if (!json) {
      return
    }
    json.animations.forEach((obj, userNo) => {
      let initialTime = OPENING_END_TIME_MS + userNo * 10 * 1000
      obj.effects.forEach((effect) => {
        if (!effect.id.includes('sound')) {
          this.changeDelay(userNo, effect.id, effect.delay + initialTime, effect.delay)
        }
      })
    })
  }

  render() {
    return (
      <div>
        <div id='screening-player-anim-pane' ref={this.animDivRef}>
          <div ref={this.endingRef} id='ending'>
            {this.state.participants === 1 && (
              <iframe
                id='ending'
                src={`/screening/ed_${this.state.character.toUpperCase()}_1.svg`}
                onLoad={() => {
                  this.changeEndrollNickname()
                }}
                title='ending'
              />
            )}
            {this.state.participants !== 1 && (
              <iframe
                id='ending'
                src={`/screening/ed_${this.state.character.toUpperCase()}_4.svg`}
                title='ending'
                onLoad={() => {
                  this.changeEndrollNickname()
                }}
              />
            )}
          </div>
          <div ref={this.no4DivRef} id='no4'>
            {this.svgBackground[3].map((obj) => obj)}
            {this.svgTextEffects3[3].map((obj) => obj)}
            {this.svgCharacter[3].map((obj) => obj)}
            {this.svgVisualEffects2[3].map((obj) => obj)}
            {this.svgTextEffects2[3].map((obj) => obj)}
            {this.svgVisualEffects1[3].map((obj) => obj)}
            {this.svgTelops[3].map((obj) => obj)}
            {this.svgCurtain.map((obj) => obj)}
          </div>
          <div ref={this.no3DivRef} id='no3'>
            {this.svgBackground[2].map((obj) => obj)}
            {this.svgTextEffects3[2].map((obj) => obj)}
            {this.svgCharacter[2].map((obj) => obj)}
            {this.svgVisualEffects2[2].map((obj) => obj)}
            {this.svgTextEffects2[2].map((obj) => obj)}
            {this.svgVisualEffects1[2].map((obj) => obj)}
            {this.svgTelops[2].map((obj) => obj)}
          </div>
          <div ref={this.loadingRef} id='loading'>
            <img
              src='/Loading_1220.svg'
              alt='loading'
              style={this.state.importStatus === 'finish' ? { visibility: 'hidden' } : { visibility: 'visible' }}
            ></img>
          </div>
          <div ref={this.no2DivRef} id='no2'>
            {this.svgBackground[1].map((obj) => obj)}
            {this.svgTextEffects3[1].map((obj) => obj)}
            {this.svgCharacter[1].map((obj) => obj)}
            {this.svgVisualEffects2[1].map((obj) => obj)}
            {this.svgTextEffects2[1].map((obj) => obj)}
            {this.svgVisualEffects1[1].map((obj) => obj)}
            {this.svgTelops[1].map((obj) => obj)}
          </div>
          <div ref={this.no1DivRef} id='no1'>
            {this.svgBackground[0].map((obj) => obj)}
            {this.svgTextEffects3[0].map((obj) => obj)}
            {this.svgCharacter[0].map((obj) => obj)}
            {this.svgVisualEffects2[0].map((obj) => obj)}
            {this.svgTextEffects2[0].map((obj) => obj)}
            {this.svgVisualEffects1[0].map((obj) => obj)}
            {this.svgTelops[0].map((obj) => obj)}
          </div>
          <div ref={this.openingDivRef} id='opening'>
            <iframe
              id='opening'
              src={`/screening/op_${this.state.character.toUpperCase()}.svg`}
              title='opening'
              onLoad={() => {
                this.loadFinish('opening')
              }}
            />
          </div>
          <div ref={this.creditDivRef} id='credit'>
            <iframe
              id='credit'
              src={`/screening/credit_title_${this.state.character.toUpperCase()}.svg`}
              onLoad={() => {
                this.changeCreditSceneNickName()
              }}
              title='credit'
            />
          </div>
          <div
            ref={this.loadingRef}
            id="loading">
              <img
                src='/Loading_1220.svg'
                alt='loading'
                style={
                  this.state.importStatus === 'finish'
                  ? {visibility: 'hidden' }
                  : {visibility: 'visible' }}></img>
            </div>
        </div>
        <div id='screening-player-audio-elems'>
          <audio
            id='sound1'
            controls
            src='/sound_effects/1.mp3'
            ref={this.audio1Ref}
            onCanPlayThrough={() => {
              this.loadFinish('sound1')
            }}
          />
          <audio
            id='sound2'
            controls
            src='/sound_effects/2.mp3'
            ref={this.audio2Ref}
            onCanPlayThrough={() => {
              this.loadFinish('sound2')
            }}
          />
          <audio
            id='sound3'
            controls
            src='/sound_effects/3.mp3'
            ref={this.audio3Ref}
            onCanPlayThrough={() => {
              this.loadFinish('sound3')
            }}
          />
          <audio
            id='sound4'
            controls
            src='/sound_effects/4.mp3'
            ref={this.audio4Ref}
            onCanPlayThrough={() => {
              this.loadFinish('sound4')
            }}
          />
          <audio
            id='sound5'
            controls
            src='/sound_effects/5.mp3'
            ref={this.audio5Ref}
            onCanPlayThrough={() => {
              this.loadFinish('sound5')
            }}
          />
          <audio
            id='sound6'
            controls
            src='/sound_effects/6.mp3'
            ref={this.audio6Ref}
            onCanPlayThrough={() => {
              this.loadFinish('sound6')
            }}
          />
          <audio
            id='bacheBgm1'
            controls
            src='/screening/BACHE_01.mp3'
            ref={this.bacheBGM1Ref}
            onCanPlayThrough={() => {
              this.loadFinish('bacheBgm1')
            }}
          />
          <audio
            id='bacheBgm2'
            controls
            src='/screening/BACHE_02.mp3'
            ref={this.bacheBGM2Ref}
            onCanPlayThrough={() => {
              this.loadFinish('bacheBgm2')
            }}
          />
          <audio
            id='bacheBgm3'
            controls
            src='/screening/BACHE_03.mp3'
            ref={this.bacheBGM3Ref}
            onCanPlayThrough={() => {
              this.loadFinish('bacheBgm3')
            }}
          />
          <audio
            id='urbanoBgm1'
            controls
            src='/screening/URBANO_01.mp3'
            ref={this.urbanoBGM1Ref}
            onCanPlayThrough={() => {
              this.loadFinish('urbanoBgm1')
            }}
          />
          <audio
            id='urbanoBgm2'
            controls
            src='/screening/URBANO_02.mp3'
            ref={this.urbanoBGM2Ref}
            onCanPlayThrough={() => {
              this.loadFinish('urbanoBgm2')
            }}
          />
          <audio
            id='urbanoBgm3'
            controls
            src='/screening/URBANO_03.mp3'
            ref={this.urbanoBGM3Ref}
            onCanPlayThrough={() => {
              this.loadFinish('urbanoBgm3')
            }}
          />
          <audio
            id='vitaBgm1'
            controls
            src='/screening/VITA_01.mp3'
            ref={this.vitaBGM1Ref}
            onCanPlayThrough={() => {
              this.loadFinish('vitaBgm1')
            }}
          />
          <audio
            id='vitaBgm2'
            controls
            src='/screening/VITA_02.mp3'
            ref={this.vitaBGM2Ref}
            onCanPlayThrough={() => {
              this.loadFinish('vitaBgm2')
            }}
          />
          <audio
            id='vitaBgm3'
            controls
            src='/screening/VITA_03.mp3'
            ref={this.vitaBGM3Ref}
            onCanPlayThrough={() => {
              this.loadFinish('vitaBgm3')
            }}
          />
        </div>
      </div>
    )
  }
}

export default ScreeningPlayer;

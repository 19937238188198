import React, { Component } from 'react'
import './ZvCursor.css'

class ZvCursor extends Component {
  componentDidUpdate() {
    console.log('ZVMousePosition: update')
  }

  render() {
    return <div id='zvCursor' style={{ left: `${this.props.x - 21}px`, top: `${this.props.y - 21}px` }} />
  }
}

export default ZvCursor


import './AppHeader.css';

function AppHeader() {
  return (
    <div className="header"/>
  );
}

export default AppHeader;

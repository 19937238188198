import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Login.css";
import Skyway from "../component/webrtc/skyway.js";
import Db, { MOVE_PAGE, LOGIN, CHOSEN_SCENES, PEERID, clearLocalStorage } from "../helper/Db";
import RedirectOtherSite from "./Redirect";
import { AnimationStudioAPI } from "../helper/AnimationStudioAPI";
import { UAParser } from 'ua-parser-js';
import { getOSVersion } from '../helper/getOSVersion';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.progressDialog = React.createRef(null);
    this.progressArea = React.createRef(null);
    this.nowComposition = false;
    this.loginResultTimeoutHandler = undefined;
    this.state = {
      failActivateRoom: false,
      inputedNickName: '',
      canStartWork: false,
      inputedNickNameError: false,
      goToTutorial: false,
      rejectedWork: false,
      waitingPageMove: false,
      startWorkAgain: false,
      isRecovery: false,
      recoveryMovePage: "",
      roomId: "",
      chara: "",
      OS: "",
      OSVersion: "",
      browserName: "",
      browserMajorVer: "",
      browserMinorVer: ""
    };
    this.skyway = new Skyway();
  }

  async componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const id_chara = params.get('id')
    if (!id_chara) {
      this.setState({
        failActivateRoom: true
      })
      return
    }
    const [id, chara] = id_chara.split('---')
    if (!id || !chara) {
      this.setState({
        failActivateRoom: true
      })
      return
    }
    const uaParser = new UAParser(window.navigator.userAgent)
    const browserVersion = uaParser.getBrowser().version.split(".")
    const newState = {
      ...this.state,
      chara: chara,
      roomId: id,
      OS: uaParser.getOS().name,
      OSVersion: await getOSVersion(uaParser),
      browserName: uaParser.getBrowser().name,
      browserMajorVer: browserVersion[0],
      browserMinorVer: browserVersion
    };
    console.log(newState.roomId, newState.chara);
    if (newState.roomId && newState.chara) {
      // Before connecting Skyway, Check the roomId can activatable.
      const validRoom = await new AnimationStudioAPI().isActiveRoom(newState.roomId);
      if (!validRoom) {
        this.setState({
          failActivateRoom: true,
        });
      }
    }

    //? Commented because we first need to check if state exists
    // else {
    //   // If we don't roomid and chara, deal as invalid room.
    //   this.setState({
    //     failActivateRoom: true,
    //   });
    //   return;
    // }

    // Determine that we can restore state from local storage.
    const login = await Db(LOGIN);
    if (login && (!newState.roomId || (newState.roomId && login.roomId === newState.roomId))) {
      newState.canStartWork = true;
      newState.inputedNickName = login.nickName;
      newState.inputedNickNameError = false;
      newState.chara = login.chara;
      newState.roomId = login.roomId;
    }

    // Connect to skyway mesh room with roomId.
    // If we have the inputted nickname from localStorage, we try to regist this nickname
    this.setState(newState, async () => {
      console.log("[newState]...", newState);
      if (newState.roomId) {
        this.skyway.meshId = newState.roomId;
        await this.openSkyway();
        this.props.setChooseCharacter(newState.chara);
        if (newState.inputedNickName) {
          this.props.setNickName(newState.inputedNickName);
          this.registNickName(true);
        }
      }
    });

    // check browser agent.
    const browser = uaParser.getBrowser();
    if (browser.name !== 'Chrome' || (browser.name === 'Chrome' && parseInt(browser.version.split('.')[0]) < 89)) {
      const msg = `このブラウザでは、体験（たいけん）することができません。
Chrome(クローム)の最新版（さいしんばん）をご利用（りよう）ください

あなたのブラウザ：${browser.name}（${this.getBrowserRuby(browser.name)}）`;
      alert(msg)
    }
  }

  getBrowserRuby(name) {
    const browserRuby = new Map();
    browserRuby.set('Chrome', 'クローム');
    browserRuby.set('Firefox', 'ファイヤーフォックス');
    browserRuby.set('Safari', 'サファリ');
    browserRuby.set('Edge', 'エッジ');
    browserRuby.set('IE', 'インターネットエクスプローラー');
    
    return browserRuby.get(name) || 'その他';
  }

  componentWillUnmount() {
    this.skyway.removeReceiveHandler("login");
  }

  async openSkyway() {
    let result = await this.skyway.open();
    if (result) {
      this.props.setSkyway(this.skyway);
    }
    await this.skyway.joinRoom();
    this.skyway.addReceiveHandler(
      {
        receive: async (e) => {
          // need to wait the ZV approval, here.
          console.log(e.data);
          e = e.data;
          let type = e.messageType;
          switch (type) {
            case "loginResult":
              if (e.nickName !== this.state.inputedNickName) return;
              if (this.loginResultTimeoutHandler) {
                clearTimeout(this.loginResultTimeoutHandler);
                this.loginResultTimeoutHandler = undefined;
              }
              if (e.isApproved) {
                // Waiting the next movePage command. Save connection info
                console.log("approved.");
                this.skyway.loginData = {
                  svId: e.svId,
                  nickName: this.state.inputedNickName,
                  isConnected: true,
                };
                Db(LOGIN, {
                  svId: e.svId,
                  nickName: this.state.inputedNickName,
                  chara: this.state.chara,
                  roomId: this.state.roomId,
                });
                Db(PEERID, this.skyway.peerId);
                const movePage = await Db(MOVE_PAGE);
                this.setState({
                  waitingPageMove: true,
                  recoveryMovePage: movePage,
                });
              } else {
                if (e.reason !== 'PENDING') {
                  this.setState({
                    rejectedWork: true,
                  });
                  this.hideProgress();
                  clearLocalStorage();
                }
              }
              break;
            case "movePage":
              if (e.target === "tutorial" && this.state.waitingPageMove) {
                this.props.setNickName(this.state.inputedNickName);
                this.props.history.push("/tutorial");
                Db(MOVE_PAGE, window.location.pathname);
              }
              break;
            default:
              break;
          }
        },
        error: (e) => {
          console.log(e);
        },
      },
      "login"
    );

    const login = await Db(LOGIN);
    // Recovery from localStorage
    if (login && login.roomId === this.state.roomId) {
      this.setState({
        canStartWork: true,
        inputedNickName: login.nickName,
        inputedNickNameError: false,
        chara: login.chara,
      });
      this.props.setChooseCharacter(login.chara);
      const chooseScenes = await Db(CHOSEN_SCENES);
      if (chooseScenes) {
        let scene = chooseScenes.filter(s => s.nickName === login.nickName);
        if (scene.length > 0) this.props.setChooseScene(scene[0].sceneId);
      }
    } else {
      await clearLocalStorage();
      this.props.setChooseCharacter(this.state.chara);
    }
  }

  onChangeName(e) {
    if (this.nowComposition) return;
    const inputed = e.target.value;
    const validationResult = inputed.match(/^[\u30a0-\u30ff\u3040-\u309fa-zA-Z]+$/) ? true : false;
    if (inputed.length <= 0) {
      this.setState({
        canStartWork: false,
        inputedNickNameError: false,
      });
    } else if (!validationResult) {
      console.log("regex error");
      this.setState({
        canStartWork: false,
        inputedNickNameError: true,
      });
    } else {
      this.setState({
        canStartWork: true,
        inputedNickName: inputed,
        inputedNickNameError: false,
      });
    }
  }

  async registNickName(isRecovery = false) {
    console.log(isRecovery);
    this.showProgress();
    // send and waiting regist nickname.
    this.skyway.sendData({
      messageType: "login",
      nickName: this.state.inputedNickName,
      id: this.state.inputedNickName,
      env: `${this.state.browserName} ${this.state.browserMajorVer}/${this.state.OS} ${this.state.OSVersion}`,
    });
    this.setState({
      isRecovery
    });
    // set the login timeout handler.
    this.loginResultTimeoutHandler = setTimeout(() => {
      this.setState({
        startWorkAgain: true,
      });
      this.hideProgress();
      this.loginResultTimeoutHandler = undefined;
    }, 10 * 1000);
  }

  showProgress() {
    // hide inputed area.
    if (this.progressDialog.current) {
      this.progressDialog.current.style.display = "block";
    }
    if (this.progressArea.current) {
      this.progressArea.current.style.display = "block";
    }
    this.setState({
      inputedNickNameError: false,
      rejectedWork: false,
      startWorkAgain: false,
    });
  }

  hideProgress() {
    if (this.progressDialog.current) {
      this.progressDialog.current.style.display = "none";
    }
    if (this.progressArea.current) {
      this.progressArea.current.style.display = "none";
    }
    this.setState({
      waitingPageMove: false,
    });
  }

  render() {
    if (this.state.failActivateRoom) {
      console.log("=================");
      console.log(this.state.roomId, this.state.chara, this.state.failActivateRoom);
      console.log("=================");
      return <RedirectOtherSite loc={process.env.REACT_APP_REDIRECT_URL} />;
    }

    return (
      <div className="Login">
        <header>
          <div>
            <img id="header_logo" src="/login/Logo.svg" alt="Welcome Logo"></img>
          </div>
        </header>
        <div id="login-content">
          <div id="announcement">
            <img src="/login/login_announcement.svg" alt="login announce"></img>
          </div>
          <div id="input_content">
            <input
              type="text"
              placeholder="ウルバノ"
              onCompositionStart={(e) => {
                this.nowComposition = true;
              }}
              onCompositionEnd={(e) => {
                this.nowComposition = false;
                this.onChangeName(e);
              }}
              onChange={(e) => {
                this.onChangeName(e);
              }}
              maxLength="6"
            ></input>
          </div>
          <div id="alert">
            {this.state.inputedNickNameError && (
              <img className="error" src="/login/alert.svg" alt="check input data"></img>
            )}
            {this.state.rejectedWork && (
              <img className="error" src="/login/reject.svg" alt="check input data"></img>
            )}
            {this.state.startWorkAgain && (
              <img
                src="/login/start_work_again.svg"
                alt="press start work again"/>
            )}
          </div>
          {this.state.canStartWork && (
            <img
              src="/login/start_work.svg"
              alt="lets start work"
              className="clickable"
              onClick={async () => {
                // 初回ニックネーム登録時に過去に登録していたローカルストレージ情報は不要なため削除
                await clearLocalStorage();
                this.registNickName();
              }}
            ></img>
          )}
          {!this.state.canStartWork && (
            <img src="/login/start_work_disabled.svg" alt="lets start work"></img>
          )}
        </div>
        <div id="disable-area" ref={this.progressArea}></div>
        <div id="progress-dialog-area" ref={this.progressDialog}>
          <div id="progress-dialog">
            <img src="/login/progress.svg" alt="progress"></img>
            <br />
            {!this.state.waitingPageMove && (
              <img src="/login/waiting_message.svg" alt="message"></img>
            )}
            {this.state.waitingPageMove && !this.state.isRecovery && (
              <img src="/login/waiting_start_message.svg" alt="message"></img>
            )}
            {this.state.waitingPageMove && this.state.isRecovery && this.state.recoveryMovePage.includes('login')  && (
              <img src="/login/waiting_start_message.svg" alt="message"></img>
              )}
            {this.state.waitingPageMove && this.state.isRecovery && !this.state.recoveryMovePage.includes('login')  && (
              <img
                src="/login/start_wrok_again_button.svg"
                className="clickable recovery-button"
                onClick={() => {
                  if (this.state.recoveryMovePage) {
                    this.props.history.push(this.state.recoveryMovePage ? this.state.recoveryMovePage : process.env.REACT_APP_REDIRECT_URL);
                  }
                }}
                alt="message"
              />
            )}

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginPage);

import React, { Component } from "react";
import "./Tutorial.css";
import { Redirect, withRouter } from "react-router-dom";
import Db, { MOVE_PAGE, TUTORIAL_NO } from "../helper/Db";

class TutorialPage extends Component {
  constructor(props) {
    super(props);
    console.log(props.skyway);

    this.audio1Ref = React.createRef();
    this.audio2Ref = React.createRef();
    this.audio3Ref = React.createRef();

    this.targetSVG = [...Array(14)].map((obj, num) => {
      return React.createRef(null);
    });
    this.currentNum = 0;

    this.soundHandler = undefined;
    this.soundHandlerStartTime = undefined;
    this.isAudio1Play = false;
    this.isAudio2Play = false;
    this.isAudio3Play = false;

    this.state = {
      gotoChooseScene: false,
      chara: "",
    };
  }

  componentDidMount() {
    if (this.props.skyway) {
      this.props.skyway.addReceiveHandler(
        {
          receive: (e) => {
            let type = e.data.messageType;
            console.log(e);
            switch (type) {
              case "changeTutorial":
                let no = e.data.tutorialNo;
                no = parseInt(no.substr(1)) - 1;
                this.changeTutorial(no);
                break;
              case "movePage":
                let page = e.data.target;
                if (page === "chooseScene") {
                  this.setState({
                    gotoChooseScene: true,
                  });
                  Db(MOVE_PAGE, "/chooseScene/");
                }
                break;
              default:
                break;
            }
          },
          error: (e) => {
            console.log(e);
          },
        },
        "tutorial"
      );

      Db(TUTORIAL_NO).then((res) => {
        if (res) {
          this.changeTutorial(res -1);
        }
      });
    } else {
      this.props.history.push("/login");
    }
  }

  componentWillUnmount() {
    if (this.props.skyway) this.props.skyway.removeReceiveHandler("tutorial");
    if (this.soundHandler) window.clearInterval(this.soundHandler);
  }

  changeTutorial(no) {
    const prevNo = this.currentNum;
    const nextNo = no;
    console.log(prevNo, nextNo);
    if (!!this.targetSVG[prevNo].current && !!this.targetSVG[nextNo].current) {
      this.targetSVG[prevNo].current.style.visibility = 'hidden'
      let svg = this.targetSVG[nextNo].current.getSVGDocument()

      // SVG not yet loaded, wait to proceed
      if (!svg) {
        setTimeout(() => this.changeTutorial(no), 1000)
        return
      }

      if (this.soundHandler) {
        window.clearInterval(this.soundHandler)
        this.soundHandler = undefined
        this.soundHandlerStartTime = undefined
        this.isAudio1Play = false
        this.isAudio2Play = false
        this.isAudio3Play = false
      }
      // If anim is No.2 and 13 animation, this animation contains the audio
      if (nextNo === 2) {
        this.soundHandlerStartTime = new Date()
        this.soundHandler = window.setInterval(() => {
          let diffTimeMS = new Date() - this.soundHandlerStartTime
          if (!this.isAudio3Play && diffTimeMS > 3300 && !!this.audio3Ref.current) {
            this.audio3Ref.current.volume = 0.3
            this.audio3Ref.current.play()
            this.isAudio3Play = true
          } else if (!this.isAudio1Play && diffTimeMS > 5360 && !!this.audio1Ref.current) {
            this.audio1Ref.current.volume = 0.3
            this.audio1Ref.current.play()
            this.isAudio1Play = true
          } else if (!this.isAudio2Play && diffTimeMS > 9000 && !!this.audio2Ref.current) {
            this.audio2Ref.current.volume = 0.3
            this.audio2Ref.current.play()
            this.isAudio2Play = true
          }
        }, 30)
      } else if (nextNo === 13) {
        this.soundHandlerStartTime = new Date()
        this.soundHandler = window.setInterval(() => {
          let diffTimeMS = new Date() - this.soundHandlerStartTime
          if (!this.isAudio3Play && diffTimeMS > 300 && !!this.audio3Ref.current) {
            this.audio3Ref.current.volume = 0.3
            this.audio3Ref.current.play()
            this.isAudio3Play = true
          } else if (!this.isAudio1Play && diffTimeMS > 2360 && !!this.audio1Ref.current) {
            this.audio1Ref.current.volume = 0.3
            this.audio1Ref.current.play()
            this.isAudio1Play = true
          } else if (!this.isAudio2Play && diffTimeMS > 6000 && !!this.audio2Ref.current) {
            this.audio2Ref.current.volume = 0.3
            this.audio2Ref.current.play()
            this.isAudio2Play = true
          }
        }, 30)
      }

      for (var anim of svg.getAnimations()) {
        anim.currentTime = 0
        anim.play()
      }
      this.targetSVG[nextNo].current.style.visibility = 'visible'
      this.currentNum = no
      Db(TUTORIAL_NO, no + 1)
    }
  }

  render() {
    if (this.state.gotoChooseScene) {
      return <Redirect to="/chooseScene/"></Redirect>;
    }
    return (
      <div className="preview" id="preview">
        <div className="main-content">
          {this.targetSVG.map((_, i) => {
            return (
              <object
                ref={this.targetSVG[i]}
                type="image/svg+xml"
                className="tutorial-svg"
                aria-label="tutorial svg"
                data={`/tutorial/T${i + 1}.svg`}
                key={`telop${i}`}
              />
            );
          })}
        </div>
        <div id="audio-elems">
          <audio id="sound1" controls src="/sound_effects/1.mp3" ref={this.audio1Ref} />
          <audio id="sound2" controls src="/sound_effects/2.mp3" ref={this.audio2Ref} />
          <audio id="sound3" controls src="/sound_effects/3.mp3" ref={this.audio3Ref} />
        </div>
      </div>
    );
  }
}

export default withRouter(TutorialPage);

export const FIXED_RENDERED_ELEMENTS = [
    "ending",
    "opening",
    "credit",
    "sound1",
    "sound2",
    "sound3",
    "sound4",
    "sound5",
    "sound6",
    "bacheBgm1",
    "bacheBgm2",
    "bacheBgm3",
    "urbanoBgm1",
    "urbanoBgm2",
    "urbanoBgm3",
    "vitaBgm1",
    "vitaBgm2",
    "vitaBgm3"
];